import React from 'react';
import ReactDOM from 'react-dom'
import Api from '../services/api';

import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip';



class indexinst extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false,
      darkMode: '',
      toggle: '',
      classe: '',
      menus: {},
      detalhesLoja: {},
      backDrop: ''
    }
    
  }

  backDropClick() {
    this.setState({
      ...this.state,
      toggle: '',
      backDrop: ''
    })
  }
  backDropToolbarClick() {
    this.setState({
      ...this.state,
      menus: {},
      backDropToolbar: ''
    })
  }

  darkMode() {
    var v = '';
    if (this.state.darkMode != 'dark') {
      v = 'dark';
    }
    this.setState({
      ...this.state,
      darkMode: v,
    });
  }

  toggle() {
    var v = '';
    var bd = '';
    if (this.state.toggle != 'sidebar-active') {
      v = 'sidebar-active';
      bd = 'displayBlock';
    }

    this.setState({
      ...this.state,
      toggle: v,
      backDrop: bd
    });
  }


  alternaMenu(item, cs) {
    var m = {};
    var bd = '';
    if (this.state.menus[item] === undefined || this.state.menus[item] === "") {
      m[item] = cs;
      bd = 'displayBlock';
    } else {
      m[item] = "";
    }
    this.setState({
      ...this.state,
      menus: m,
      backDropToolbar: bd
    });
  }

  doLogout() {
    localStorage.clear();
    Api.get("/v1/users/logout").then((rps) => {
      if(rps.data.status==true){
        window.location.href = '/'
      }
    })
  }

  render() {
    const { children } = this.props;
    //let acesso = JSON.parse(localStorage.getItem('@ADM-Aiboo/perfil'));

    return (
      <>

      
      <div id="wrapper" className="flex flex-col justify-between h-screen">
        {/* header*/}
        <div className="bg-white py-4 shadow dark:bg-gray-800">
          <div className="max-w-6xl mx-auto">
            <div className="flex items-center lg:justify-between justify-around">
              <a href="trending.html">
                <img src="assets/images/logo.png" alt="" className="w-32" />
              </a>
              <div className="capitalize flex font-semibold hidden lg:block my-2 space-x-3 text-center text-sm">
                <Link to="/login">
                <button className="py-3 px-4">Login</button>
                </Link>
                <Link to="/register">
                <button className="bg-pink-500 pink-500 px-6 py-3 rounded-md shadow text-white">Registrar</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Content*/}
        {children}
      </div>


      </>
    )
  }
};
export default indexinst;