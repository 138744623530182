/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useParams, withRouter } from "react-router";
import classNames from 'classnames'
import Modal from "react-bootstrap/Modal";
import LoadingOverlay from 'react-loading-overlay';

import Select from 'react-select'
import Api from '../../../services/api';
import Swal from 'sweetalert2';
import { useToasts } from 'react-toast-notifications';

import { Headerer } from '../../../components/headerer'
import { Paginator } from '../../../components/paginator'
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { AprofundamentoTematico } from '../../../components/AprofundamentoTematico';

const AcademicoMaterias = () => {
    const { id } = useParams();

    const { addToast } = useToasts();
    const stsOpt = [{ value: 'ATIVA', label: "Ativa" }, { value: 'INATIVA', label: "Inativa" }]
    const [modalIsOpen, setIsOpen] = useState(false);

    useEffect(() => {
        getDisciplinaSelect();
        //getMaterias();
    }, []);

    function mapa(id, nome){
        sessionStorage.setItem('idTema', id);
        sessionStorage.setItem('nomeMateria', nome);
    }

    const [tabSelected, setTabSelected] = React.useState('cards');

    /*Carregamento dos cards em si - inicio */
    const [materias, setMaterias] = useState([]);
    const [formMaterias, setFormMaterias] = useState({});
    const [actPage, setActPage] = useState();
    const [totPage, setTotPage] = useState();
    const [changePageReferreds, setChangePageReferreds] = useState(null);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState({ field: {} });

    function getMaterias(page) {
        setLoading(true);
        var selected = (id === null || id === undefined) ? '' : '/'+id;
        Api.post("/materias/list" + selected + "?page=" + page, search).then((rps) => {
            setLoading(false);
            
            if (rps.data.status !== true) {
                Swal.fire({
                    title: "Erro!",
                    icon: "error",
                    html: rps.data.message,
                    showCloseButton: true,
                    showCancelButton: false,
                });
                return;
            }
            
                setMaterias(rps.data.data);
                setTotPage(rps.data.last_page);
                setActPage(rps.data.current_page);
        })
    }

    const [disciplinaSelect, setDisciplinaSelect] = useState([]);
    function getDisciplinaSelect() {

        Api.get("disciplinas").then(rps => {
            var dis = rps.data.obj;
            var nDis = [];
            nDis[nDis.length] = { value: '', label: 'Selecione...' };
            dis.forEach(e => {
                nDis[nDis.length] = { value: e.id, label: e.nome };
            })

            setDisciplinaSelect(nDis);
        })
    }

    useEffect(() => {
        if (changePageReferreds === null)
            return;
        getMaterias(changePageReferreds);
    }, [changePageReferreds])

    useEffect(() => {
        console.log(search);
        setActPage(1);
        getMaterias(1);
    }, [search])
    /*Carregamento dos cards em si - final */

    function editar(row) {
        var r = JSON.parse(JSON.stringify(row));
        setFormMaterias(r);
        setIsOpen(true);
    }

    function adicionar() {
        var selected = (id === null || id === undefined) ? null : id;
        console.log(selected);

        setFormMaterias({ id_disciplina: selected });
        setIsOpen(true);
    }

    function handleFechaModal() {
        setIsOpen(false);
    }

    function salvar() {
        Api.post("/materias/save", formMaterias).then((rps) => {

            if (rps.data.status === true) {
                addToast(rps.data.message, {
                    appearance: "success",
                    autoDismiss: true,
                    autoDismissTimeout: 2000
                });
            }
        })
        setIsOpen(false);
        getMaterias();
    }

    function excluir(row) {
        var data = JSON.parse(JSON.stringify(row));

        setLoading(true);

        Swal.fire({
            icon: "question",
            title: 'Confirmação',
            html: 'Deseja excluir a Disciplina?',
            showCancelButton: true,
            confirmButtonText: `Sim, Confirmar!`,
            cancelButtonText: 'Não, Cancelar.'
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {

                Api.post('/materias/delete', data).then(rps => {
                    if (rps.data.status == true) {
                        addToast(rps.data.message, {
                            appearance: "success",
                            autoDismiss: true,
                            autoDismissTimeout: 2000
                        });
                        getMaterias();
                    }
                })
            }
            setLoading(false);
        })
    }

    return (
        <>
  

            <div className="d-flex flex-column-fluid">
                <div className="container">

                <div id="kt_app_toolbar" className="app-toolbar  pt-5">
        <div id="kt_app_toolbar_container" className="app-container d-flex flex-stack ">
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0 text-uppercase">
              Matéria 
            </h1>
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <Link className="text-muted text-hover-primary" to="/academico/disciplinas">
                    Disciplinas
                </Link>
               </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px" />
              </li>
              <li className="breadcrumb-item text-muted">
                {sessionStorage.getItem('nomeDiscplina')}                               
              </li>
            </ul>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3">
              <button className='btn btn-sm btn-primary' onClick={e => { adicionar() }}><i className="fas fa-plus" />Adicionar</button>
          </div>
        </div>
      </div>

                    <div className="card card-custom gutter-b example example-compact">
                        <div className="mt-5">

                            <LoadingOverlay
                                active={loading}
                                spinner
                            >
                                 <table className="table table-striped" id="kt_table_users">

                                    <thead className="bg-light-primary thead-inputs">
                                        <Headerer
                                            columns={[
                                                { name: 'ID', id: 'materias.id', searchable: true, sortable: true },
                                                { name: 'Disciplina', id: 'disciplinas.nome', searchable: true, sortable: true },
                                                { name: 'Nome', id: 'materias.nome', searchable: true, sortable: true },
                                                { name: 'Cards', id: 'cards', searchable: false, sortable: false },
                                                { name: 'Resumo', id: 'at', searchable: false, sortable: false },
                                                { name: 'Temas', id: 'temas', searchable: false, sortable: false },
                                                {
                                                    name: 'Status', id: 'materias.status', searchable: false, sortable: true,
                                                    selects: [
                                                        { name: "Ativa", value: 'ATIVA' },
                                                        { name: "Inativa", value: 'INATIVA' }
                                                    ]
                                                },
                                                { name: 'Ação', id: 'action', searchable: false, sortable: false },
                                            ]}
                                            setSearchOrig={setSearch}
                                            searchOrig={search}
                                        />
                                    </thead>

                                    <tbody className="text-gray-600 fw-bold">
                                        { 
                                        materias.map((mat) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td className='text-center max-width-id'>{mat.id}</td>
                                                        <td>{mat.nome_disciplina}</td>
                                                        <td>{mat.nome}</td>
                                                       
                                                       
                                                        <td className='text-center min-width-c-q'>
                                                            <label className="badge badge-light-info badge-tag fw-bolder"><i className="fa fa-brain text-info"></i> {mat.cards}</label>
                                                            <label className="badge badge-light-pink badge-tag fw-bolder"><i className="fa fa-edit text-pink"></i> {mat.questoes}</label>
                                                        </td>
                                                       

                                                        <td className='text-center'>
                                                            {mat.texto_livre !== null || mat.legislacao !== null || mat.doutrina !== null || mat.jurisprudencia !== null || mat.sumulas !== null ?
                                                                <span class="badge badge-success mr-2">Sim</span> 
                                                            :
                                                                <span class="badge badge-secondary mr-2">Não</span>}
                                                        </td>

                                                        <td className='text-center'>
                                                            <label className="badge badge-light-info badge-tag fw-bolder">{mat.temas}</label>
                                                        </td>


                                                        <td className='text-center'>
                                                            {mat.status == "ATIVA" &&
                                                                <span class="badge badge-success mr-2">Ativa</span>}
                                                            {mat.status == "INATIVA" &&
                                                                <span class="badge badge-secondary mr-2">Inativa</span>}
                                                        </td>
                                                        <td className='text-center'>
                                                            <Dropdown>
                                                            <Dropdown.Toggle class="btn-drop btn btn-primary">
                                                            <i class="flaticon-more"></i>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item>
                                                                        <Link to={"/academico/temas/" + mat.id} onClick={e => { mapa(mat.id, mat.nome)}}>
                                                                            <a class="dropdown-item">Temas</a>
                                                                        </Link>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item>
                                                                        <a class="dropdown-item" onClick={e => { e.preventDefault(); editar(mat) }} >Editar</a>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item>
                                                                        <a class="dropdown-item" onClick={e => { e.preventDefault(); excluir(mat) }} >Excluir</a>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>

                                                </>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </LoadingOverlay>

                            <Paginator maxPages={totPage} actPage={actPage} setNextPage={setChangePageReferreds} loading={loading} />

                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Card*/}


                    {/*end::Post*/}
                    <Modal
                        enforceFocus={false}
                        size={"lg"}
                        show={modalIsOpen}
                        onHide={() => handleFechaModal()}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Materias</Modal.Title>
                        </Modal.Header>
                        
                        <Modal.Body>

                        <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 text-uppercase font-bold">
                                <li class="nav-item">
                                    <a
                                        onClick={e => { e.preventDefault(); setTabSelected('cards'); }}
                                        className={classNames('nav-link', { 'active': tabSelected === 'cards' })}
                                    >
                                        Cards
                                    </a>
                                </li>

                                <li class="nav-item">
                                    <a
                                        onClick={e => { e.preventDefault(); setTabSelected('at'); }}
                                        className={classNames('nav-link', { 'active': tabSelected === 'at' })}
                                    >
                                        Resumo
                                    </a>
                                </li>
                            </ul>


                            {
                                (() => {
                                    switch (tabSelected) {
                                        case 'cards':
                                            return <div>


                            <div className="row ml-3 mr-3 mt-3">
                                <div className="form-group col-md-12">
                                    <label>Disciplina <span class="text-danger">*</span></label>
                                    <Select
                                        value={disciplinaSelect.find(
                                            x => parseInt(x.value + '') === parseInt(formMaterias.id_disciplina + '')
                                        )}
                                        class="form-select"
                                        options={disciplinaSelect}
                                        onChange={e => { setFormMaterias({ ...formMaterias, id_disciplina: e.value }) }}
                                    />
                                </div>
                            </div>

                            <div className="row ml-3 mr-3 mt-3">
                                <div className="form-group col-md-12">
                                    <label>Nome <span class="text-danger">*</span></label>
                                    <input className="form-control"
                                        value={formMaterias?.nome}
                                        onChange={(e) => { setFormMaterias({ ...formMaterias, nome: e.target.value }) }} />
                                </div>
                            </div>

                            <div className="row ml-3 mr-3 mt-3">
                                <div className="form-group col-md-12">
                                    <label>Status <span class="text-danger">*</span></label>
                                    <Select
                                        value={stsOpt.find(
                                            x => x.value === formMaterias?.status
                                        )}
                                        options={stsOpt}
                                        onChange={e => { setFormMaterias({ ...formMaterias, status: e.value }) }}
                                    />
                                </div>
                            </div>
                            </div>;

case 'at':
    return <div>

                            <AprofundamentoTematico get={formMaterias} set={setFormMaterias} />

                            </div>
                                                ;
                                        default:
                                            return null;
                                    }
                                })()
                            }


                        </Modal.Body>

                        <Modal.Footer>
                            <button onClick={() => handleFechaModal()} type="button" class="btn btn-light" data-bs-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
                            <button onClick={e => { e.preventDefault(); salvar() }} type="button" class="btn btn-success"><i className="fas fa-save" />Salvar</button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </>
    );

}

export default withRouter(AcademicoMaterias);                  