import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';

import Api from '../../services/api';
import LayoutInst from '../../layout/index_inst.jsx';
import { ButtonLoading } from '../../components/ButtonLoading';


const Login = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const [frmLogin, setFrmLogin] = useState({ email: '', password: '' });
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    document.title = "Jurídika"
  }, []);



  function doLogin() {
    setLoading(true);
    Api.post("/auth/login", frmLogin).then((rps) => {
      setLoading(false);
      if (rps.data.status != true) {
        addToast(rps.data.message, {
          appearance: "warning",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
      } else {
        try {
          var token = rps.data.token;

          localStorage.setItem('hash_session', token)
          Api.defaults.headers['Authorization'] = 'Bearer ' + token
          
          history.push("/feed");

          /*.then( e => {
              console.log("a");
          })*/



        } catch (error) {
          console.log('Erro ao armazenar session', error)
          return error
        }
      }
    })
  }



  return (
    <>

      {/* Content*/}
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="col-lg-6 center-vertical d-none d-lg-block" style={{ backgroundColor: '#111' }}>
            <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y">
              <div className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20">
                <img alt="Logo" src="assets/images/celular_preto.png" className="logo-login" />
              </div>
            </div>
          </div>


        

          <div className="d-flex flex-column flex-lg-row-fluid py-10 ">
            <div className="d-flex flex-center flex-column flex-column-fluid">
              <div className="w-100 p-10 p-lg-15 mx-auto">
                <div className="text-center mb-10">

                <img alt="Logo" src="assets/images/logo/logo_juridika_horiz_light.png" className="logo-login2" />


                </div>
                <form>
                <div className="fv-row mb-10">
                  <label className="form-label fs-6 fw-bolder text-dark">Usuário::</label>
                  <input
                    value={frmLogin.email}
                    onChange={(e) => { setFrmLogin({ ...frmLogin, email: e.target.value }) }}
                    className="form-control form-control-lg form-control-solid" type="text" name="email" autoComplete="off" />
                </div>
                <div className="fv-row mb-10">
                  <div className="d-flex flex-stack mb-2">
                    <label className="form-label fw-bolder text-dark fs-6 mb-0">Senha</label>
                  </div>
                  <input
                    value={frmLogin.password}
                    onChange={(e) => { setFrmLogin({ ...frmLogin, password: e.target.value }) }}
                    className="form-control form-control-lg form-control-solid" type="password" name="password" autoComplete="off" />
                </div>
                <div className="text-center">
                  <ButtonLoading isLoading={loading} onClick={e => { e.preventDefault(); doLogin(); }} id="kt_sign_in_submit" type="submit" className="btn btn-lg btn-primary w-100 mb-5 fw-bolder">
                    <span className="indicator-label">Acessar</span>
                    
                  </ButtonLoading>
                </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

}



export default withRouter(Login);                  