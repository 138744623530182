import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";

import ReactImageVideoLightbox from "react-image-video-lightbox";

const AccountSettings = () => {
  useEffect(() => {
    document.title = "Minha conta  | 2Close.com"
  }, []);
  return (
    <>


<div className="container m-auto"> 
        <h1 className="text-2xl leading-none text-gray-900 tracking-tight mt-3"> Configurações de Conta </h1>
        <ul className="mt-5 -mr-3 flex-nowrap lg:overflow-hidden overflow-x-scroll uk-tab">
          <li className="uk-active"><a href="#">Perfil</a></li>
          <li><a href="#">Conta</a></li>
          <li><a href="#">Notificações</a></li>
          <li><a href="#">Segurança</a></li>
        </ul>
        <div className="grid lg:grid-cols-3 mt-12 gap-8">
          <div>
            <h3 className="text-xl mb-2"> Perfil</h3>
            <p> Lorem ipsum dolor sit amet nibh consectetuer adipiscing elit</p>
          </div>
          <div className="bg-white rounded-md lg:shadow-lg shadow col-span-2">
            <div className="grid grid-cols-2 gap-3 lg:p-6 p-4">
              {/* <div>
                <label htmlFor> First name</label>
                <input type="text" placeholder="Your name.." className="shadow-none bg-gray-100" />
              </div>
              <div>
                <label htmlFor> Last name</label>
                <input type="text" placeholder="Your name.." className="shadow-none bg-gray-100" />
              </div> */}
              <div className="col-span-2">
                <label htmlFor> @ de usuário</label>
                <input type="text" placeholder="Your name.." className="shadow-none bg-gray-100" />
              </div>
              <div className="col-span-2">
                <label htmlFor> Apelido</label>
                <input type="text" placeholder="Your name.." className="shadow-none bg-gray-100" />
              </div>
              <div className="col-span-2">
                <label htmlFor="about">Biografia</label>  
                <textarea id="about" name="about" rows={2} placeholder="Your name.." className="shadow-none bg-gray-100" defaultValue={""} />
              </div> 
              <div className="col-span-2">
                <label htmlFor> Localização</label>
                <input type="text" placeholder className="shadow-none bg-gray-100" />
              </div>
            </div> 
            <div className="bg-gray-10 p-6 pt-0 flex justify-end space-x-3">
              <button className="p-2 px-4 rounded bg-gray-50 text-red-500"> Cancel </button>
              <button type="button" className="button bg-blue-700"> Save </button>
            </div>
          </div>

          <div>
            <h3 className="text-xl mb-2"> Conta</h3>
            <p> Lorem ipsum dolor sit amet nibh consectetuer adipiscing elit</p>
          </div>
          
          <div className="bg-white rounded-md lg:shadow-lg shadow col-span-2">
            <div className="grid grid-cols-2 gap-3 lg:p-6 p-4">
              {/* <div>
                <label htmlFor> First name</label>
                <input type="text" placeholder="Your name.." className="shadow-none bg-gray-100" />
              </div>
              <div>
                <label htmlFor> Last name</label>
                <input type="text" placeholder="Your name.." className="shadow-none bg-gray-100" />
              </div> */}
              <div className="col-span-2">
                <label htmlFor> E-mail</label>
                <input type="text" placeholder="Your name.." className="shadow-none bg-gray-100" />
              </div>
              <div className="col-span-2">
                <label htmlFor> Senha Atual</label>
                <input type="text" placeholder className="shadow-none bg-gray-100" />
              </div>
              <div className="col-span-2">
                <label htmlFor> Nova Senha</label>
                <input type="text" placeholder className="shadow-none bg-gray-100" />
              </div>
              <div className="col-span-2">
                <label htmlFor> Confirmar Nova Senha</label>
                <input type="text" placeholder className="shadow-none bg-gray-100" />
              </div>
            </div> 
            <div className="bg-gray-10 p-6 pt-0 flex justify-end space-x-3">
              <button className="p-2 px-4 rounded bg-gray-50 text-red-500"> Cancel </button>
              <button type="button" className="button bg-blue-700"> Save </button>
            </div>
          </div>


          <div>
            <h3 className="text-xl mb-2"> Notificacões</h3>
            <p> Lorem ipsum dolor sit amet nibh consectetuer adipiscing elit</p>
          </div>

          <div className="bg-white rounded-md lg:shadow-lg shadow lg:p-6 p-4 col-span-2">
            <div className="space-y-3">
              <div className="flex justify-between items-center">
                <div>
                  <h4> Who can follow me ?</h4>
                  <div> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, </div>
                </div>
                <div className="switches-list -mt-8 is-large">
                  <div className="switch-container">
                    <label className="switch"><input type="checkbox" /><span className="switch-button" /> </label>
                  </div>
                </div>
              </div>
              <hr />
              <div className="flex justify-between items-center">
                <div>
                  <h4> Show my activities ?</h4>
                  <div> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, </div>
                </div>
                <div className="switches-list -mt-8 is-large">
                  <div className="switch-container">
                    <label className="switch"><input type="checkbox" defaultChecked /><span className="switch-button" /> </label>
                  </div>
                </div>
              </div>
              <hr />
              <div className="flex justify-between items-center">
                <div>
                  <h4> Search engines?</h4>
                  <div> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, </div>
                </div>
                <div className="switches-list -mt-8 is-large">
                  <div className="switch-container">
                    <label className="switch"><input type="checkbox" /><span className="switch-button" /> </label>
                  </div>
                </div>
              </div>
              <hr />
              <div className="flex justify-between items-center">
                <div>
                  <h4> Allow Commenting?</h4>
                  <div> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, </div>
                </div>
                <div className="switches-list -mt-8 is-large">
                  <div className="switch-container">
                    <label className="switch"><input type="checkbox" /><span className="switch-button" /> </label>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div>
            <h3 className="text-xl mb-2"> Segurança</h3>
            <p> Lorem ipsum dolor sit amet nibh consectetuer adipiscing elit</p>
          </div>
          <div className="bg-white rounded-md lg:shadow-lg shadow lg:p-6 p-4 col-span-2">
            <div className="space-y-3">
              <div className="flex justify-between items-center">
                <div>
                  <h4> Aplicativo Autenticador</h4>
                  <div> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, </div>
                </div>
                <div className="switches-list -mt-8 is-large">
                  <div className="switch-container">
                    <label className="switch"><input type="checkbox" /><span className="switch-button" /> </label>
                  </div>
                </div>
              </div>
              <hr />
              <div className="flex justify-between items-center">
                <div>
                  <h4> Verificação via SMS</h4>
                  <div> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, </div>
                </div>
                <div className="switches-list -mt-8 is-large">
                  <div className="switch-container">
                    <label className="switch"><input type="checkbox" /><span className="switch-button" /> </label>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>
    </>
  );

}



export default withRouter(AccountSettings);                  