import classNames from 'classnames';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { CKEditor } from '@ckeditor/ckeditor5-react';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicExtended from "ckeditor5-build-classic-extended";

var timeoutChange = null;

export function AprofundamentoTematico({ set, get}) {


    return (
        <>
            <p>Texto livre</p>          
            <CKEditor
                editor={ ClassicExtended }
                config={{         
                    toolbar: ['heading', '|', 'bold', 'italic', 'underline', 'strikethrough', 'link', '|', 'numberedList', 'bulletedList', 
                    'indent', 'outdent', '|', 'imageUpload', 'blockQuote', 'insertTable', 'mediaEmbed', '|', 'undo', 'redo']
                  }}
                data={get['texto_livre']}
                onChange={( event, editor ) => { set({...get, texto_livre: editor.getData()}) }}            
            />
            <br />
            <br />
            <p>Legislação</p>
            <CKEditor
                editor={ ClassicExtended }
                config={{         
                    toolbar: ['heading', '|', 'bold', 'italic', 'underline', 'strikethrough', 'link', '|', 'numberedList', 'bulletedList', 
                    'indent', 'outdent', '|', 'imageUpload', 'blockQuote', 'insertTable', 'mediaEmbed', '|', 'undo', 'redo']
                  }}
                data={get['legislacao']}
                onChange={( event, editor ) => { set({...get, legislacao: editor.getData()}) }}            
            />
            <br />
            <br />
            <p>Doutrina</p>
            <CKEditor
                editor={ ClassicExtended }
                config={{         
                    toolbar: ['heading', '|', 'bold', 'italic', 'underline', 'strikethrough', 'link', '|', 'numberedList', 'bulletedList', 
                    'indent', 'outdent', '|', 'imageUpload', 'blockQuote', 'insertTable', 'mediaEmbed', '|', 'undo', 'redo']
                  }}
                data={get['doutrina']}
                onChange={( event, editor ) => { set({...get, doutrina: editor.getData()}) }}            
            />
            <br />
            <br />
            <p>Jurisprudencia</p>
            <CKEditor
                editor={ ClassicExtended }
                config={{         
                    toolbar: ['heading', '|', 'bold', 'italic', 'underline', 'strikethrough', 'link', '|', 'numberedList', 'bulletedList', 
                    'indent', 'outdent', '|', 'imageUpload', 'blockQuote', 'insertTable', 'mediaEmbed', '|', 'undo', 'redo']
                  }}
                data={get['jurisprudencia']}
                onChange={( event, editor ) => { set({...get, jurisprudencia: editor.getData()}) }}            
            />
            <br />
            <br />
            <p>Sumulas</p>
            <CKEditor
                editor={ ClassicExtended }
                config={{         
                    toolbar: ['heading', '|', 'bold', 'italic', 'underline', 'strikethrough', 'link', '|', 'numberedList', 'bulletedList', 
                    'indent', 'outdent', '|', 'imageUpload', 'blockQuote', 'insertTable', 'mediaEmbed', '|', 'undo', 'redo']
                  }}
                data={get['sumulas']}
                onChange={( event, editor ) => { set({...get, sumulas: editor.getData()}) }}            
            />
            <br />
            <br />

        </>
    )
}
