/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useParams, withRouter } from "react-router";
import classNames from 'classnames'
import Modal from "react-bootstrap/Modal";
import LoadingOverlay from 'react-loading-overlay';

import Select from 'react-select'
import Api from '../../../services/api';
import Swal from 'sweetalert2';
import { useToasts } from 'react-toast-notifications';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import { Headerer } from '../../../components/headerer'
import { Paginator } from '../../../components/paginator'
import { AprofundamentoTematico } from '../../../components/AprofundamentoTematico';


const AcademicoSubTemas = () => {
    const { id } = useParams();

    const { addToast } = useToasts();
    const stsOpt = [{ value: 'ATIVA', label: "Ativa" }, { value: 'INATIVA', label: "Inativa" }]
    const [modalIsOpen, setIsOpen] = useState(false);

    useEffect(() => {
        getTemaSelect();
        getSubTemas();
    }, []);

    const [tabSelected, setTabSelected] = React.useState('cards');

    /*Carregamento dos cards em si - inicio */
    const [subTemas, setSubTemas] = useState([]);
    const [formSubTemas, setFormSubTemas] = useState({});
    const [actPage, setActPage] = useState();
    const [totPage, setTotPage] = useState();
    const [changePageReferreds, setChangePageReferreds] = useState(null);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState({ field: {} });
    function getSubTemas(page) {
        setLoading(true);
        console.log(id);
        var selected = (id === null || id === undefined) ? '' : '/'+id;
        Api.post("/subtemas/list" + selected + "?page=" + page, search).then((rps) => {
            setLoading(false);

            if (rps.data.status !== true) {
                Swal.fire({
                    title: "Erro!",
                    icon: "danger",
                    html: rps.data.message,
                    showCloseButton: true,
                    showCancelButton: false,
                });
                return;
            }

            setSubTemas(rps.data.data);
            setTotPage(rps.data.last_page);
            setActPage(rps.data.current_page);
        })
    }

    const [temaSelect, setTemaSelect] = useState([]);
    function getTemaSelect() {

        Api.get("temas/listarAtivo").then(rps => {
            var tem = rps.data.obj;
            var nTem = [];
            nTem[nTem.length] = { value: '', label: 'Selecione...' };
            tem.forEach(e => {
                nTem[nTem.length] = { value: e.id, label: e.nome };
            })
            setTemaSelect(nTem);
        })
    }


    useEffect(() => {
        if (changePageReferreds === null)
            return;
        getSubTemas(changePageReferreds);
    }, [changePageReferreds])

    useEffect(() => {
        setActPage(1);
        getSubTemas(1);
    }, [search])
    /*Carregamento dos cards em si - final */

    function editar(row) {
        var r = JSON.parse(JSON.stringify(row));
        setFormSubTemas(r);
        setIsOpen(true);
    }

    function adicionar() {
        var selected = (id === null || id === undefined) ? null : id;
        console.log(selected);

        setFormSubTemas({ id_tema: id });
        setIsOpen(true);
    }

    function handleFechaModal() {
        setIsOpen(false);
    }

    function salvar() {
        Api.post("/subtemas/save", formSubTemas).then((rps) => {

            if (rps.data.status === true) {
                addToast(rps.data.message, {
                    appearance: "success",
                    autoDismiss: true,
                    autoDismissTimeout: 2000
                });
            }
        })
        setIsOpen(false);
        getSubTemas();
    }

    function excluir(row) {
        var data = JSON.parse(JSON.stringify(row));

        setLoading(true);

        Swal.fire({
            icon: "question",
            title: 'Confirmação',
            html: 'Deseja excluir a Disciplina?',
            showCancelButton: true,
            confirmButtonText: `Sim, Confirmar!`,
            cancelButtonText: 'Não, Cancelar.'
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {

                Api.post('/subtemas/delete', data).then(rps => {
                    if (rps.data.status == true) {
                        addToast(rps.data.message, {
                            appearance: "success",
                            autoDismiss: true,
                            autoDismissTimeout: 2000
                        });
                        getSubTemas();
                    }

                })
            }
            setLoading(false);
        })
    }

    return (
        <>

            <div className="d-flex flex-column-fluid">
                <div className="container">

                <div id="kt_app_toolbar" className="app-toolbar  pt-5">
        <div id="kt_app_toolbar_container" className="app-container d-flex flex-stack ">
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
              SUBTEMAS
            </h1>
                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                  <li className="breadcrumb-item text-muted">
                    <Link className="text-muted text-hover-primary" to="/academico/disciplinas">
                        Disciplina
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <span className="bullet bg-gray-400 w-5px h-2px" />
                  </li>
                  <li className="breadcrumb-item text-muted">
                    <Link className="text-muted text-hover-primary" to={"/academico/materias/"+sessionStorage.getItem('idMateria')}>
                        {sessionStorage.getItem('nomeDiscplina')}
                    </Link>                                
                  </li>
                  <li className="breadcrumb-item">
                    <span className="bullet bg-gray-400 w-5px h-2px" />
                  </li>
                  <li className="breadcrumb-item text-muted">
                      <Link className="text-muted text-hover-primary" to={"/academico/temas/"+sessionStorage.getItem('idTema')}>
                        {sessionStorage.getItem('nomeMateria')}
                      </Link>                                
                  </li>
                  <li className="breadcrumb-item">
                    <span className="bullet bg-gray-400 w-5px h-2px" />
                  </li>
                  <li className="breadcrumb-item text-muted">
                    {sessionStorage.getItem('nomeTema')}                                
                  </li>
                </ul>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3">
              <button className='btn btn-sm btn-primary' onClick={e => { adicionar() }}><i className="fas fa-plus" />Adicionar</button>
          </div>
        </div>
      </div>

                    <div className="card card-custom gutter-b example example-compact">
                        <div className="mt-5">

                            <LoadingOverlay
                                active={loading}
                                spinner
                            >
                              <table className="table table-striped" id="kt_table_users">

                                    <thead className="bg-light-primary thead-inputs">
                                        <Headerer
                                            columns={[
                                                { name: 'ID', id: 'subtemas.id', searchable: true, sortable: true },
                                                { name: 'Tema', id: 'temas.nome', searchable: true, sortable: true },
                                                { name: 'Nome', id: 'subtemas.nome', searchable: true, sortable: true },
                                                {
                                                    name: 'Status', id: 'subtemas.status', searchable: false, sortable: true,
                                                    selects: [
                                                        { name: "Ativa", value: 'ATIVA' },
                                                        { name: "Inativa", value: 'INATIVA' }
                                                    ]
                                                },
                                                { name: 'Questões', id: 'action', searchable: false, sortable: false },
                                                { name: 'AT', id: 'action', searchable: false, sortable: false },
                                                { name: 'Ação', id: 'action', searchable: false, sortable: false },
                                            ]}
                                            setSearchOrig={setSearch}
                                            searchOrig={search}
                                        />
                                    </thead>

                                    <tbody className="text-gray-600 fw-bold">
                                        {subTemas.map((tema) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td className='text-center'>{tema.id}</td>
                                                        <td>{tema.nome_tema}</td>
                                                        <td>{tema.nome}</td>
                                                        <td className='text-center'>
                                                            {tema.texto_livre !== null || tema.legislacao !== null || tema.doutrina !== null || tema.jurisprudencia !== null || tema.sumulas !== null ?
                                                                <span class="badge badge-success mr-2">Sim</span> 
                                                            :
                                                                <span class="badge badge-secondary mr-2">Não</span>}
                                                        </td>
                                                        <td className='text-center'>
                                                            <label className="badge badge-light-info badge-tag mb-2 fw-bolder">{tema.questoes}</label>
                                                        </td>
                                                        <td className='text-center'>
                                                            {tema.status == "ATIVA" &&
                                                                <span class="badge badge-success mr-2">Ativa</span>}
                                                            {tema.status == "INATIVA" &&
                                                                <span class="badge badge-secondary mr-2">Inativa</span>}
                                                        </td>
                                                        <td className='text-center'>
                                                            <Dropdown>
                                                            <Dropdown.Toggle class="btn-drop btn btn-primary">
                                                            <i class="flaticon-more"></i>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item>
                                                                        <a class="dropdown-item" onClick={e => { e.preventDefault(); editar(tema) }} >Editar</a>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item>
                                                                        <a class="dropdown-item" onClick={e => { e.preventDefault(); excluir(tema) }} >Excluir</a>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>

                                                </>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </LoadingOverlay>

                            <Paginator maxPages={totPage} actPage={actPage} setNextPage={setChangePageReferreds} loading={loading} />

                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Card*/}


                    {/*end::Post*/}
                    <Modal
                        enforceFocus={false}
                        size={"lg"}
                        show={modalIsOpen}
                        onHide={() => handleFechaModal()}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Subtemas</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row ml-3 mr-3 mt-3">
                                <div className="form-group col-md-12">
                                    <label>Tema <span class="text-danger">*</span></label>
                                    <Select
                                        options={temaSelect}
                                        placeholder="Selecione..."
                                        defaultValue={temaSelect.find(
                                            x => parseInt(x.value + '') === parseInt(formSubTemas?.id_tema + '')
                                        )}
                                        onChange={(e) => { setFormSubTemas({ ...formSubTemas, id_tema: e.value }) }}
                                    />
                                </div>
                            </div>

                            <div className="row ml-3 mr-3 mt-3">
                                <div className="form-group col-md-12">
                                    <label>Nome <span class="text-danger">*</span></label>
                                    <input className="form-control"
                                        value={formSubTemas?.nome}
                                        onChange={(e) => { setFormSubTemas({ ...formSubTemas, nome: e.target.value }) }} />
                                </div>
                            </div>

                            <div className="row ml-3 mr-3 mt-3">
                                <div className="form-group col-md-12">
                                    <label>Status <span class="text-danger">*</span></label>
                                    <Select
                                        value={stsOpt.find(
                                            x => x.value === formSubTemas?.status
                                        )}
                                        options={stsOpt}
                                        onChange={e => { setFormSubTemas({ ...formSubTemas, status: e.value }) }}
                                    />
                                </div>
                            </div>

                            AT
                            <AprofundamentoTematico get={formSubTemas} set={setFormSubTemas} />

                        </Modal.Body>

                        <Modal.Footer>
                            <button onClick={() => handleFechaModal()} type="button" class="btn btn-light" data-bs-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
                            <button onClick={e => { e.preventDefault(); salvar() }} type="button" class="btn btn-success"><i className="fas fa-save" />Salvar</button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </>
    );

}

export default withRouter(AcademicoSubTemas);                  