/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import classNames from 'classnames'
import Modal from "react-bootstrap/Modal";
import api from '../../../services/api';
import Accordion from 'react-bootstrap/Accordion';
import LoadingOverlay from 'react-loading-overlay';

import Select from 'react-select'
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AcademicoCategorias = () => {


  useEffect(() => {
    getTaxonomia();
  }, []);

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [tabSelected, setTabSelected] = React.useState('detalhes');


  function handleFechaModal() {
    setIsOpen(false);
  }

  function abreModal() {
    console.log("abre model");
    setIsOpen(true);
  }

  const [modalDisciplina, setModalDisciplina] = useState(false);
  function openModalDisciplina(disciplina) {

    setModalDisciplina(true);
  }


  function handleFechaModal() {
    setIsOpen(false);
  }


  const [taxonomia, setTaxonomia] = useState([]);
  const [loading, setLoading] = useState(false);
  function getTaxonomia() {
    setLoading(true);
    api.post("/taxonomia/list", {}).then((rps) => {
      if (rps.data.status !== true) {
        alert(rps.data.message);
        return;
      }
      setTaxonomia(rps.data.disciplinas);
      setLoading(false);
    })
  }

  return (
    <>
      <div className="toolbar" id="kt_toolbar">
        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
          <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex text-dark fw-bolder fs-3 align-items-center my-1">Gerenciar Categorias</h1>
            <span className="h-20px border-gray-300 border-start mx-4" />
            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li className="breadcrumb-item text-muted">
                <a href="../../demo1/dist/index.html" className="text-muted text-hover-primary">Acadêmico</a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-300 w-5px h-2px" />
              </li>
              <li className="breadcrumb-item text-muted">Categorias</li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-300 w-5px h-2px" />
              </li>
              <li className="breadcrumb-item text-dark">Gerenciar Categorias</li>
            </ul>
          </div>


        </div>
      </div>
      <div className="post d-flex flex-column-fluid mt-10" id="kt_post">
        <div id="kt_content_container" className="container-xxl">
          <div className="card">
            <div className="card-header border-0 pt-6">
              <div className="card-title">
                <div className="d-flex align-items-center position-relative my-1">
                  <span className="svg-icon svg-icon-1 position-absolute ms-6">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height={2} rx={1} transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                      <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
                    </svg>
                  </span>
                  <input type="text" data-kt-user-table-filter="search" className="form-control form-control-solid w-250px ps-14" placeholder="Buscar card" />
                </div>
              </div>
              <div className="card-toolbar">

                <div className="d-flex justify-content-end align-items-center d-none" data-kt-user-table-toolbar="selected">
                  <div className="fw-bolder me-5">
                    <span className="me-2" data-kt-user-table-select="selected_count" />Selected</div>
                  <button type="button" className="btn btn-danger" data-kt-user-table-select="delete_selected">Delete Selected</button>
                </div>
              </div>
            </div>


            <div className="card-body py-4">
              <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                
                <LoadingOverlay
                                active={loading}
                                spinner
                            > 
                <div className="table-responsive">
                  <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_table_users">
                  <thead className="d-inline-flex mb-3">
                    <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 table-responsive d-contents ">
                      <th className="flex-auto espaco-header-col col-taxonomia">Taxonomia</th>
                      <th className="flex-auto espaco-header-col col-at">AT</th>
                      <th className="flex-auto espaco-header-col col-cards-questoes">Vínculos</th>
                      <th className="flex-auto espaco-header-col col-status">Status</th>
                      <th className="text-end espaco-header-col flex-auto col-acoes">Ações</th></tr>
                  </thead>

                    <tbody className="text-gray-600 fw-bold">
                      <Accordion defaultActiveKey="0">

                        {taxonomia.map((disciplina, index) => {

                          return (
                            <>
                              <Accordion.Item eventKey={index}>
                                <Accordion.Header>
                                  <tr className="odd d-contents">
                                    <td className="col-taxonomia flex-auto">
                                      <div className="d-flex flex-column col-taxonomia">
                                        <p class="text-1 espaco-taxonomia-1"> {disciplina.disciplina.nome}</p>
                                      </div>
                                    </td>
                                    <td className="flex-auto col-at">
                                      <div className="badge badge-light fw-bolder">SIM</div>
                                    </td>
                                    <td className="flex-auto col-cards-questoes">
                                      <div className="badge badge-light-info badge-tag mb-2 fw-bolder"><i className="fa fa-brain text-info"></i> {disciplina.count}</div>
                                      {/* <div className="badge badge-light-info badge-tag fw-bolder"><i className="fa fa-question-circle text-info"></i> XX</div> */}
                                    </td>
                                    <td className="flex-auto col-status">
                                      <div className="badge badge-light-success fw-bolder">Ativo</div>
                                    </td>
                                    <td className="text-end flex-auto col-acoes">
                                      <a onClick={(e) => { openModalDisciplina(disciplina) }} className="btn btn-light btn-active-light-primary btn-sm"><i className="fa fa-chevron-down"></i> Gerenciar</a>

                                      <Dropdown>
                                        <Dropdown.Toggle class="btn-drop btn btn-primary">
                                          <i class="flaticon-more"></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>

                                          <Dropdown.Item>
                                            <Link to={"/academico/materias/" + disciplina.disciplina.id}>
                                              <a class="dropdown-item">Materias</a>
                                            </Link>
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <Link to={"/academico/tags/" + disciplina.disciplina.id}>
                                              <a class="dropdown-item">Tags</a>
                                            </Link>
                                          </Dropdown.Item>

                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                  </tr>
                                </Accordion.Header>
                                <Accordion.Body>



                                  <Accordion defaultActiveKey="0">

                                    {disciplina.materias.map((materia, index2) => {
                                      return (<>
                                        <Accordion.Item eventKey={index2}>
                                          <Accordion.Header>
                                            <tr className="odd table-children1 d-contents">
                                              <td className="col-taxonomia flex-auto">
                                                <div className="d-flex flex-column">
                                                  <p class="text-2 espaco-taxonomia-1"> {materia.materia.nome}</p>
                                                </div>
                                              </td>
                                              <td className="col-at flex-auto">
                                                <div className="badge badge-light fw-bolder">SIM</div>
                                              </td>
                                              <td className="flex-auto col-cards-questoes">
                                                <div className="badge badge-light-info badge-tag mb-2 fw-bolder"><i className="fa fa-brain text-info"></i> {materia.count}</div>
                                                {/* <div className="badge badge-light-info badge-tag fw-bolder"><i className="fa fa-question-circle text-info"></i> XX</div> */}
                                              </td>
                                              <td className="flex-auto col-status">
                                                <div className="badge badge-light-success fw-bolder">Ativo</div>
                                              </td>
                                              <td className="text-end col-acoes flex-auto">

                                                <Dropdown>
                                                  <Dropdown.Toggle class="btn-drop btn btn-primary">
                                                    <i class="flaticon-more"></i>
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu>

                                                    <Dropdown.Item>
                                                      <Link to={"/academico/temas/" + materia.materia.id}>
                                                        <a class="dropdown-item">Temas</a>
                                                      </Link>
                                                    </Dropdown.Item>

                                                  </Dropdown.Menu>
                                                </Dropdown>

                                              </td>
                                            </tr>
                                          </Accordion.Header>
                                          <Accordion.Body>


                                            {materia.temas.map(tema => {
                                              return (<>
                                                <div className="espaco-tema-col">
                                                  <tr className="odd table-children1 d-contents">
                                                    <td className="col-taxonomia flex-auto">
                                                      <div className="d-flex flex-column table-children-2-pd">
                                                        <p class="text-2">{tema.tema.nome}</p>
                                                      </div>
                                                    </td>
                                                    <td className="flex-auto col-at">
                                                      <div className="badge badge-light fw-bolder">SIM</div>
                                                    </td>
                                                    <td className="flex-auto col-cards-questoes">
                                                      <div className="badge badge-light-info badge-tag mb-2 fw-bolder"><i className="fa fa-brain text-info"></i> {tema.count}</div>
                                                      {/* <div className="badge badge-light-info badge-tag fw-bolder"><i className="fa fa-question-circle text-info"></i> XX</div> */}
                                                    </td>
                                                    <td className="flex-auto col-status">
                                                      <div className="badge badge-light-danger fw-bolder">Inativo</div>
                                                    </td>
                                                    <td className="text-end col-acoes flex-auto">
                                                      <Dropdown>
                                                        <Dropdown.Toggle class="btn-drop btn btn-primary">
                                                          <i class="flaticon-more"></i>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>

                                                          <Dropdown.Item>
                                                            <Link to={"/academico/temas/cards/" + tema.tema.id}>
                                                              <a class="dropdown-item">Cards</a>
                                                            </Link>
                                                          </Dropdown.Item>

                                                          <Dropdown.Item>
                                                            <Link to={"/academico/subtemas/" + tema.tema.id}>
                                                              <a class="dropdown-item">Subtemas</a>
                                                            </Link>
                                                          </Dropdown.Item>

                                                        </Dropdown.Menu>
                                                      </Dropdown>

                                                    </td>
                                                  </tr>
                                                </div>
                                              </>)
                                            })}

                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </>
                                      )
                                    })}

                                  </Accordion>
                                </Accordion.Body>
                              </Accordion.Item>

                            </>)
                        })}

                      </Accordion>

                    </tbody>
                  
                  </table>
                </div>
                </LoadingOverlay>

                <div className="row">
                  <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" />
                  <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    <div className="dataTables_paginate paging_simple_numbers" id="kt_table_users_paginate">
                      <ul className="pagination">
                        <li className="paginate_button page-item previous disabled" id="kt_table_users_previous">
                          <a href="#" aria-controls="kt_table_users" data-dt-idx={0} tabIndex={0} className="page-link">
                            <i className="previous" />
                          </a>
                        </li>
                        <li className="paginate_button page-item active">
                          <a href="#" aria-controls="kt_table_users" data-dt-idx={1} tabIndex={0} className="page-link">1</a>
                        </li>
                        <li className="paginate_button page-item ">
                          <a href="#" aria-controls="kt_table_users" data-dt-idx={2} tabIndex={0} className="page-link">2</a>
                        </li>
                        <li className="paginate_button page-item ">
                          <a href="#" aria-controls="kt_table_users" data-dt-idx={3} tabIndex={0} className="page-link">3</a>
                        </li>
                        <li className="paginate_button page-item next" id="kt_table_users_next">
                          <a href="#" aria-controls="kt_table_users" data-dt-idx={4} tabIndex={0} className="page-link">
                            <i className="next" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*end::Post*/}
          <Modal
            size={"md"}
            show={modalDisciplina}
            onHide={() => setModalDisciplina(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Gerenciar Matéria/Tema</Modal.Title>
            </Modal.Header>
            <Modal.Body>


              <div className="card">
                <div class="card-header card-header-stretch overflow-auto">
                  <ul class="nav nav-stretch nav-line-tabs flex-nowrap">
                    <li class="nav-item">
                      <a
                        onClick={e => { e.preventDefault(); setTabSelected('detalhes'); }}
                        className={classNames('nav-link', { 'active': tabSelected === 'detalhes' })}
                      >
                        Detalhes
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        onClick={e => { e.preventDefault(); setTabSelected('at'); }}
                        className={classNames('nav-link', { 'active': tabSelected === 'at' })}
                      >
                        AT
                      </a>
                    </li>
                  </ul>
                </div>


                {
                  (() => {
                    switch (tabSelected) {
                      case 'detalhes':
                        return <div>


                          <div className="card">
                            <div className="mb-4">
                              <label class="form-label">Nome da Disciplina</label>
                              <input type="text" class="form-control"></input>
                            </div>

                            <div className="mb-4">
                              <label class="form-label">Status</label>
                              <select class="form-select" >
                                <option value="0">Ativo</option>
                                <option value="1">Inativo</option>
                              </select>
                            </div>
                          </div>


                        </div>;

                      case 'at':
                        return <div>

                          <div className="card-body">
                            At
                          </div>

                        </div>;
                      default:
                        return null;
                    }
                  })()
                }
              </div>


            </Modal.Body>

            <Modal.Footer>
              <button onClick={() => handleFechaModal()} type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
              <button type="button" class="btn btn-success">Salvar Alterações</button>
            </Modal.Footer>
          </Modal>




          <Modal
            size={"md"}
            show={modalIsOpen}
            onHide={() => handleFechaModal()}
          >
            <Modal.Header closeButton>
              <Modal.Title>Gerenciar Disciplina</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <div className="card">
                <div className="mb-4">
                  <label class="form-label">Nome da Disciplina</label>
                  <input type="text" class="form-control"></input>
                </div>

                <div className="mb-4">
                  <label class="form-label">Status</label>
                  <select class="form-select" >
                    <option value="0">Ativo</option>
                    <option value="1">Inativo</option>
                  </select>
                </div>
              </div>

            </Modal.Body>

            <Modal.Footer>
              <button onClick={() => handleFechaModal()} type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
              <button type="button" class="btn btn-success">Salvar Alterações</button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}


export default withRouter(AcademicoCategorias);                  