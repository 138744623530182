import React from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import Layout from '../layout';

import Error404 from '../pages/404'

import Swal from 'sweetalert2';
import { useToasts } from 'react-toast-notifications';
import Login from '../pages/home';


import Dashboard from '../pages/dashboard';

import AccountSettings from '../pages/account/settings';
import AccountProfile from '../pages/account/profile';

import AcademicoCards from '../pages/academico/cards';
import AcademicoQuestoes from '../pages/academico/questoes';
import AcademicoCategorias from '../pages/academico/categorias';
import AcademicoDisciplinas from '../pages/academico/disciplinas';
import AcademicoMaterias from '../pages/academico/materias';
import AcademicoTemas from '../pages/academico/temas';
import AcademicoTags from '../pages/academico/tags';
import AcademicoSubTemas from '../pages/academico/subtemas';

import UsuariosGerenciar from '../pages/usuarios/gerenciar';

import AcessoAdmin from '../pages/configuracoes/acesso-admin';


//const { addToast } = useToasts();


// Verificar se o usuário está autenticado
const isAuthenticated = () => {
  //MATAR NECESSIDADE DO LOGIN!! :
  //return true;

  if ("hash_session" in localStorage) {
    return true
  } else {
    Swal.fire(
      'Erro!',
      'Você não está logado, tente novamente.',
      'error'
    )
    return false
  }
};
// A função abaixo é para proteger uma rota
const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login" }} />
      )
    }
  />
);

const Routes = (props) => (
  <HashRouter>
    <Switch>
      { /* Rotas de Login não necessitam de Auth */}
      <Route exact path="/" component={Login}></Route>
      <Route exact path="/login" component={Login}></Route>


      { /* ------------------------------------- */}
      <Layout>
        <AuthenticatedRoute exact path="/dashboard" component={Dashboard} />

        <AuthenticatedRoute exact path="/account/settings" component={AccountSettings} />
        <AuthenticatedRoute exact path="/account/profile" component={AccountProfile} />

        <AuthenticatedRoute exact path="/academico/cards" component={AcademicoCards} />
        <AuthenticatedRoute exact path="/academico/questoes" component={AcademicoQuestoes} />
        <AuthenticatedRoute exact path="/academico/categorias" component={AcademicoCategorias} />

        <AuthenticatedRoute exact path="/academico/disciplinas" component={AcademicoDisciplinas} />

        <AuthenticatedRoute exact path="/academico/materias" component={AcademicoMaterias} />
        <AuthenticatedRoute exact path="/academico/materias/:id" component={AcademicoMaterias} />

        <AuthenticatedRoute exact path="/academico/tags" component={AcademicoTags} />
        <AuthenticatedRoute exact path="/academico/tags/:id" component={AcademicoTags} />

        <AuthenticatedRoute exact path="/academico/temas" component={AcademicoTemas} />
        <AuthenticatedRoute exact path="/academico/temas/:id" component={AcademicoTemas} />
        <AuthenticatedRoute exact path="/academico/temas/cards/:id_tema" component={AcademicoCards} />
        <AuthenticatedRoute exact path="/academico/temas/questoes/:id_tema" component={AcademicoQuestoes} />

        <AuthenticatedRoute exact path="/academico/subtemas" component={AcademicoSubTemas} />
        <AuthenticatedRoute exact path="/academico/subtemas/:id" component={AcademicoSubTemas} />
  
        <AuthenticatedRoute exact path="/usuarios/gerenciar" component={UsuariosGerenciar} />

        <AuthenticatedRoute exact path="/configuracoes/acesso-admin" component={AcessoAdmin} />

      </Layout>

      <Route path="*" component={Error404}></Route>


    </Switch>
  </HashRouter>
);

export default Routes;