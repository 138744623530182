import { useState } from "react";

export function ButtonLoading({ children, isLoading, ...props }) {
  const [loading, setLoading] = useState(false);
  /*const handleClick = async (event) => {
    if (isLoading) {
      setLoading(true);
    }
    await props.onClick(event);
    setLoading(false);
  };*/
  return (
    <button {...props} disabled={(isLoading || props.disabled)}>
      {isLoading ? 
      
      <>
        <i className="spinner-border spinner-border-sm align-middle mr-1" />{' '}
        {children}
    </>
      : children}
    </button>
  );
}