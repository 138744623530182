/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import classNames from 'classnames'
import Modal from "react-bootstrap/Modal";
import LoadingOverlay from 'react-loading-overlay';

import Select from 'react-select'
import Api from '../../../services/api';
import Swal from 'sweetalert2';
import { useToasts } from 'react-toast-notifications';

import { Headerer } from '../../../components/headerer'
import { Paginator } from '../../../components/paginator'
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { AprofundamentoTematico } from '../../../components/AprofundamentoTematico';
import { ButtonLoading } from '../../../components/ButtonLoading';

const AcademicoDisciplinas = () => {

    const { addToast } = useToasts();
    const stsOpt = [{ value: 'ATIVA', label: "Ativa" }, { value: 'INATIVA', label: "Inativa" }]
    const [modalIsOpen, setIsOpen] = useState(false);

    useEffect(() => {
        getDisciplinas();
    }, []);

    function mapa(id, nome) {
        sessionStorage.setItem('idMateria', id);
        sessionStorage.setItem('nomeDiscplina', nome);
    }

    const [tabSelected, setTabSelected] = React.useState('cards');

    /*Carregamento dos cards em si - inicio */
    const [disciplinas, setDisciplinas] = useState([]);
    const [formDisciplinas, setFormDisciplinas] = useState({});
    const [actPage, setActPage] = useState();
    const [totPage, setTotPage] = useState();
    const [changePageReferreds, setChangePageReferreds] = useState(null);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState({ field: {} });
    function getDisciplinas(page) {
        setLoading(true);
        Api.post("/disciplinas/list?page=" + page, search).then((rps) => {
            setLoading(false);

            if (rps.data.status !== true) {
                Swal.fire({
                    title: "Erro!",
                    icon: "danger",
                    html: rps.data.message,
                    showCloseButton: true,
                    showCancelButton: false,
                });
                return;
            }

            setDisciplinas(rps.data.data);
            setTotPage(rps.data.last_page);
            setActPage(rps.data.current_page);
        })
    }

    useEffect(() => {
        if (changePageReferreds === null)
            return;
        getDisciplinas(changePageReferreds);
    }, [changePageReferreds])

    useEffect(() => {
        setActPage(1);
        getDisciplinas(1);
    }, [search])
    /*Carregamento dos cards em si - final */

    function editar(row) {
        var r = JSON.parse(JSON.stringify(row));
        setFormDisciplinas(r);
        setIsOpen(true);
    }

    function adicionar() {
        setFormDisciplinas({});
        setIsOpen(true);
    }

    function handleFechaModal() {
        setIsOpen(false);
    }

    function salvar() {
        Api.post("/disciplinas/save", formDisciplinas).then((rps) => {

            if (rps.data.status === true) {
                addToast(rps.data.message, {
                    appearance: "success",
                    autoDismiss: true,
                    autoDismissTimeout: 2000
                });
            }
        })
        setIsOpen(false);
        getDisciplinas();
    }

    function excluir(row) {
        var data = JSON.parse(JSON.stringify(row));

        setLoading(true);

        Swal.fire({
            icon: "question",
            title: 'Confirmação',
            html: 'Deseja excluir a Disciplina?',
            showCancelButton: true,
            confirmButtonText: `Sim, Confirmar!`,
            cancelButtonText: 'Não, Cancelar.'
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {

                Api.post('/disciplinas/delete', data).then(rps => {
                    if (rps.data.status == true) {
                        addToast(rps.data.message, {
                            appearance: "success",
                            autoDismiss: true,
                            autoDismissTimeout: 2000
                        });
                        getDisciplinas();
                    }

                })
            }
            setLoading(false);
        })
    }

    const defaultFrmImportar = { disciplina: { nome: '' } };
    const [frmImportar, setFrmImportar] = useState(defaultFrmImportar);
    const [modalImportar, setModalImportar] = useState(false);
    const [loadingImportar, setLoadingImportar] = useState(false);
    function salvarImportacao() {
        setLoadingImportar(true);


        Api.post('disciplinas/importar', frmImportar).then((rps) => {
            setLoadingImportar(false);
            if (rps.data.status === true) {
                getDisciplinas();
                Swal.fire(rps.data.message);
                setModalImportar(false);
            }

            Swal.fire(rps.data.message);
        })

    }

    return (
        <>



            {/*begin::Post*/}
            <div className="d-flex flex-column-fluid">
                {/*begin::Container*/}
                <div className="container">


                    <div id="kt_app_toolbar" className="app-toolbar  pt-5">
                        <div id="kt_app_toolbar_container" className="app-container d-flex flex-stack ">
                            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                                    Disciplinas
                                </h1>
                                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                    <li className="breadcrumb-item text-muted">
                                        <Link className="text-muted text-hover-primary" to="/dashboard">
                                            Home
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <span className="bullet bg-gray-400 w-5px h-2px" />
                                    </li>
                                    <li className="breadcrumb-item text-muted">
                                        Dashboards                                          </li>
                                </ul>
                            </div>
                            <div className="d-flex align-items-center gap-2 gap-lg-3">
                                <button className='btn btn-sm btn-primary' onClick={e => { adicionar() }}><i className="fas fa-plus" />Adicionar</button>
                            </div>
                        </div>
                    </div>

                    <div className="card card-custom gutter-b example example-compact">
                        <div className="mt-5">

                            <LoadingOverlay
                                active={loading}
                                spinner
                            >


                                <table className="table table-striped" id="kt_table_users">

                                    <thead className="bg-light-primary thead-inputs">
                                        <Headerer
                                            columns={[
                                                { name: 'ID', id: 'id', searchable: true, sortable: true },
                                                { name: 'Nome', id: 'nome', searchable: true, sortable: true },
                                                { name: 'Tags', id: 'action', searchable: false, sortable: false },
                                                { name: 'Cards', id: 'action', searchable: false, sortable: false },
                                                { name: 'Matérias', id: 'action', searchable: false, sortable: false },
                                                {
                                                    name: 'Status', id: 'status', searchable: false, sortable: true,
                                                    selects: [
                                                        { name: "Ativa", value: 'ATIVA' },
                                                        { name: "Inativa", value: 'INATIVA' }
                                                    ]
                                                },
                                                { name: 'Ações', id: 'action', searchable: false, sortable: false }
                                            ]}
                                            setSearchOrig={setSearch}
                                            searchOrig={search}
                                        />
                                    </thead>

                                    <tbody className="text-gray-600 fw-bold">
                                        {disciplinas.map((disp) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td className='text-center max-width-id'>{disp.id}</td>
                                                        <td>{disp.nome}</td>
                                                      
                                                       
                                                        <td className='text-center'>
                                                            <label className="badge badge-primary badge-tag fw-bolder">{disp.tags}</label>
                                                        </td>
                                                        <td className='text-center'>
                                                            <label className="badge badge-light-info badge-tag fw-bolder"><i className="fa fa-brain text-info"></i> {disp.cards}</label>
                                                            <label className="badge badge-light-pink badge-tag fw-bolder"><i className="fa fa-edit text-pink"></i> {disp.questoes}</label>
                                                        </td>

                                                        <td className='text-center'>
                                                            <label className="badge badge-light-info badge-tag fw-bolder">{disp.materias}</label>
                                                        </td>

                                                        <td className='text-center'>
                                                            {disp.status == "ATIVA" &&
                                                                <span class="badge badge-success mr-2">Ativa</span>}
                                                            {disp.status == "INATIVA" &&
                                                                <span class="badge badge-secondary mr-2">Inativa</span>}
                                                        </td>
                                                        <td className='text-center'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle class="btn-drop btn btn-primary">
                                                                    <i class="flaticon-more"></i>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item>
                                                                        <Link to={"/academico/materias/" + disp.id} onClick={e => { mapa(disp.id, disp.nome) }} >
                                                                            <a class="dropdown-item">Materia</a>
                                                                        </Link>
                                                                    </Dropdown.Item>

                                                                    <Dropdown.Item>
                                                                        <Link to={"/academico/tags/" + disp.id} onClick={e => { mapa(disp.id, disp.nome) }} >
                                                                            <a class="dropdown-item">Tags</a>
                                                                        </Link>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item>
                                                                        <a onClick={e => { setFrmImportar({ disciplina: disp }); setModalImportar(true) }}>
                                                                        <a class="dropdown-item">Importar cards</a>
                                                                        </a>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item>
                                                                        <a class="dropdown-item" onClick={e => { e.preventDefault(); editar(disp) }} >Editar</a>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item>
                                                                        <a class="dropdown-item" onClick={e => { e.preventDefault(); excluir(disp) }} >Excluir</a>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </td>
                                                    </tr>

                                                </>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </LoadingOverlay>

                            <Paginator maxPages={totPage} actPage={actPage} setNextPage={setChangePageReferreds} loading={loading} />

                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Card*/}


                    {/*end::Post*/}
                    <Modal
                        enforceFocus={false}
                        size={"lg"}
                        show={modalIsOpen}
                        onHide={() => handleFechaModal()}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Disciplinas</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>


                            <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 text-uppercase font-bold">
                                <li class="nav-item">
                                    <a
                                        onClick={e => { e.preventDefault(); setTabSelected('cards'); }}
                                        className={classNames('nav-link', { 'active': tabSelected === 'cards' })}
                                    >
                                        Cards
                                    </a>
                                </li>
                            </ul>


                            {
                                (() => {
                                    switch (tabSelected) {
                                        case 'cards':
                                            return <div>

                                                <div className="row ml-3 mr-3">
                                                    <div className="form-group col-md-12">
                                                        <label>Nome <span class="text-danger">*</span></label>
                                                        <input className="form-control"
                                                            value={formDisciplinas?.nome}
                                                            onChange={(e) => { setFormDisciplinas({ ...formDisciplinas, nome: e.target.value }) }} />
                                                    </div>
                                                </div>

                                                <div className="row ml-3 mr-3 mt-3">
                                                    <div className="form-group col-md-12">
                                                        <label>Status <span class="text-danger">*</span></label>
                                                        <Select
                                                            value={stsOpt.find(
                                                                x => x.value === formDisciplinas?.status
                                                            )}
                                                            options={stsOpt}
                                                            onChange={e => { setFormDisciplinas({ ...formDisciplinas, status: e.value }) }}
                                                        />
                                                    </div>
                                                </div>

                                            </div>;

                                        case 'at':
                                            return <div>
                                                <AprofundamentoTematico get={formDisciplinas} set={setFormDisciplinas} />

                                            </div>
                                                ;
                                        default:
                                            return null;
                                    }
                                })()
                            }


                        </Modal.Body>

                        <Modal.Footer>
                            <button onClick={() => handleFechaModal()} type="button" class="btn btn-light" data-bs-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
                            <button onClick={e => { e.preventDefault(); salvar() }} type="button" class="btn btn-success"><i className="fas fa-save" />Salvar</button>
                        </Modal.Footer>
                    </Modal>



                    <Modal
                        enforceFocus={false}
                        size={"lg"}
                        show={modalImportar}
                        onHide={() => setModalImportar(false)}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Importar cards para materia {frmImportar?.disciplina.nome}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <textarea className="w-100" rows="5" disabled={loadingImportar} value={frmImportar.texto} onChange={e => { setFrmImportar({ ...frmImportar, texto: e.target.value }) }}>

                            </textarea>

                        </Modal.Body>

                        <Modal.Footer>
                            <button onClick={() => setModalImportar(false)} type="button" class="btn btn-light" data-bs-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
                            <ButtonLoading isLoading={loadingImportar} onClick={e => { e.preventDefault(); salvarImportacao() }} type="button" class="btn btn-success"><i className="fas fa-save" />Salvar</ButtonLoading>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </>
    );

}

export default withRouter(AcademicoDisciplinas);                  