import React from 'react';
import ReactDOM from 'react-dom'
import Api from '../services/api';

import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip';

import "../_assets/plugins/global/plugins.bundle.css";
import "../_assets/css/style.bundle.css";
//import "../assets/css/bootstrap.min.css";

import "../_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
import "../_metronic/_assets/plugins/flaticon/flaticon.css";
import "../_metronic/_assets/plugins/flaticon2/flaticon.css";

<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"></link>




class index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: '',
      collapsed2: '',
      darkMode: '',
      toggle: '',
      classe: '',
      menus: {},
      detalhesLoja: {},
      backDrop: ''
    }

  }

  backDropClick() {
    this.setState({
      ...this.state,
      collapsed: '',
      collapsed2: ''
    })
  }
  backDropToolbarClick() {
    this.setState({
      ...this.state,
      menus: {},
      backDropToolbar: ''
    })
  }

  darkMode() {
    var v = '';
    if (this.state.darkMode != 'dark') {
      v = 'dark';
    }
    this.setState({
      ...this.state,
      darkMode: v,
    });
  }

  toggle() {
    var menu = '';
    var menu2 = '';

    if (this.state.collapsed == 'on') {
      menu = '';
      menu2 = '';
    } else {
      menu = 'on';
      menu2 = 'drawer drawer-on';
    }

    console.log(menu);

    this.setState({
      ...this.state,
      collapsed: menu,
      collapsed2: menu2
    });
  }


  alternaMenu(item, cs) {
    var m = {};
    var bd = '';
    if (this.state.menus[item] === undefined || this.state.menus[item] === "") {
      m[item] = cs;
      bd = 'displayBlock';
    } else {
      m[item] = "";
    }
    this.setState({
      ...this.state,
      menus: m,
      backDropToolbar: bd
    });
  }

  doLogout() {
    localStorage.clear();
    Api.get("/v1/users/logout").then((rps) => {
      if (rps.data.status == true) {
        window.location.href = '/'
      }
    })
  }

  render() {
    const { children } = this.props;
    //let acesso = JSON.parse(localStorage.getItem('@ADM-Aiboo/perfil'));

    return (
      <>
        <div data-kt-aside-minimize={this.state.collapsed}>
          {/*begin::Main*/}
          {/*begin::Root*/}
          <div className="d-flex flex-column flex-root">
            {/*begin::Page*/}
            <div className="page d-flex flex-row flex-column-fluid">
              {/*begin::Aside*/}
              <div id="kt_aside" className={"aside aside-dark aside-hoverable " + this.state.collapsed2} data-kt-drawer="true" data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_mobile_toggle" style={{}}>
                {/*begin::Brand*/}
                <div className="aside-logo flex-column-auto" id="kt_aside_logo">
                  {/*begin::Logo*/}
                  <a href="../../demo1/dist/index.html">
                    <img alt="Logo" src="assets/images/logo/logo_juridika_horiz_dark.png" className="h-30px logo" />
                  </a>
                  {/*end::Logo*/}
                  {/*begin::Aside toggler*/}
                  {/* <div id="kt_aside_toggle" onClick={e => {this.toggle()}} className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">
            <span className="svg-icon svg-icon-1 rotate-180">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                <path opacity="0.5" d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z" fill="currentColor" />
                <path d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z" fill="currentColor" />
              </svg>
            </span>
          </div> */}
                  {/*end::Aside toggler*/}
                </div>
                {/*end::Brand*/}
                {/*begin::Aside menu*/}
                <div className="aside-menu flex-column-fluid">
                  {/*begin::Aside Menu*/}
                  <div className="hover-scroll-overlay-y my-5 my-lg-5" id="kt_aside_menu_wrapper" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer" data-kt-scroll-wrappers="#kt_aside_menu" data-kt-scroll-offset={0} style={{ height: '465px' }}>
                    {/*begin::Menu*/}
                    <div className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500" id="#kt_aside_menu" data-kt-menu="true" data-kt-menu-expand="false">
                      <div data-kt-menu-trigger="click" className={"menu-item menu-accordion " + this.state.menus?.dashboards}>
                        <Link className="menu-link" to="/dashboard">
                          <i className="menu-icon flaticon2-architecture-and-city"></i>
                          <span className="menu-title">Dashboard</span>
                        </Link>
                      </div>
                      {/* </div>
              <div className="menu-item">
                <div className="menu-content pt-8 pb-2">
                  <span className="menu-section text-muted text-uppercase fs-8 ls-1">Crafted</span>
                </div>
              </div> */}
                      <div data-kt-menu-trigger="click" className={"menu-item menu-accordion " + this.state.menus?.academico}>
                        <span onClick={e => { this.alternaMenu('academico', 'show'); }} className="menu-link">
                          <span className="menu-icon">
                            <i className="menu-icon flaticon2-browser-2"></i>
                          </span>
                          <span className="menu-title">Acadêmico</span>
                          <span className="menu-arrow" />
                        </span>
                        <div className="menu-sub menu-sub-accordion menu-active-bg">

                          <div className="menu-item">
                            <Link className="menu-link" to="/academico/disciplinas">
                              <span className="menu-bullet">
                                <span className="bullet bullet-dot" />
                              </span>
                              <span className="menu-title">Disciplinas</span>
                            </Link>
                          </div>

                          <div className="menu-item">
                            <Link className="menu-link" to="/academico/categorias">
                              <span className="menu-bullet">
                                <span className="bullet bullet-dot" />
                              </span>
                              <span className="menu-title">Taxonomia</span>
                            </Link>
                          </div>




                        </div>
                      </div>



                      <div data-kt-menu-trigger="click" className={"menu-item menu-accordion " + this.state.menus?.configuracoes}>
                        <span onClick={e => { this.alternaMenu('configuracoes', 'show'); }} className="menu-link">
                          <i className="menu-icon flaticon2-settings"></i>
                          <span className="menu-title">Configurações</span>
                          <span className="menu-arrow" />
                        </span>
                        <div className="menu-sub menu-sub-accordion menu-active-bg">
                          <div className="menu-item">
                            <Link className="menu-link" to="/configuracoes/acesso-admin">
                              <span className="menu-bullet">
                                <span className="bullet bullet-dot" />
                              </span>
                              <span className="menu-title">Acesso Admin</span>
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div data-kt-menu-trigger="click" className={"menu-item menu-accordion " + this.state.menus?.dashboards}>
                        <Link className="menu-link">
                          <i className="menu-icon flaticon-logout"></i>
                          <span className="menu-title">Logout</span>
                        </Link>
                      </div>


                    </div>
                    {/*end::Menu*/}
                  </div>
                  {/*end::Aside Menu*/}
                </div>
                {/*end::Aside menu*/}
                {/*begin::Footer*/}
                {/* <div className="aside-footer flex-column-auto pt-5 pb-7 px-5" id="kt_aside_footer">
          <a href="../../demo1/dist/documentation/getting-started.html" className="btn btn-custom btn-primary w-100" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss-="click" title data-bs-original-title="200+ in-house components and 3rd-party plugins">
            <span className="btn-label">Docs &amp; Components</span>
            <span className="svg-icon btn-icon svg-icon-2">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor" />
                <rect x={7} y={17} width={6} height={2} rx={1} fill="currentColor" />
                <rect x={7} y={12} width={10} height={2} rx={1} fill="currentColor" />
                <rect x={7} y={7} width={6} height={2} rx={1} fill="currentColor" />
                <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor" />
              </svg>
            </span>
          </a>
        </div> */}
                {/*end::Footer*/}
              </div>
              {/*end::Aside*/}
              {/*begin::Wrapper*/}
              <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                {/*begin::Header*/}
                <div id="kt_header" style={{}} className="header align-items-stretch">
                  {/*begin::Container*/}
                  <div className="container-fluid d-flex align-items-stretch justify-content-between">
                    {/*begin::Aside mobile toggle*/}
                    <div onClick={e => { this.toggle() }} className="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show aside menu">
                      <div className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px" id="kt_aside_mobile_toggle">
                        {/*begin::Svg Icon | path: icons/duotune/abstract/abs015.svg*/}
                        <span className="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                            <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor" />
                            <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="currentColor" />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </div>
                    </div>
                    {/*end::Aside mobile toggle*/}
                    {/*begin::Mobile logo*/}
                    <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                      <a href="../../demo1/dist/index.html" className="d-lg-none">
                        <img alt="Logo" src="assets/images/logo/logo_juridika_horiz_dark.png" className="h-30px logo" />
                      </a>
                    </div>
                    {/*end::Mobile logo*/}
                    {/*begin::Wrapper*/}
                    <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                      {/*begin::Navbar*/}
                      <div className="d-flex align-items-stretch" id="kt_header_nav"><div className="header-menu align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}" style={{}}>
                        {/*begin::Menu*/}
                        <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch" id="#kt_header_menu" data-kt-menu="true">
                          {/* MENU LARDO ESQUERDO */}
                        </div>
                        {/*end::Menu*/}
                      </div>
                        {/*begin::Menu wrapper*/}
                        {/*end::Menu wrapper*/}
                      </div>
                      {/*end::Navbar*/}
                      {/*begin::Toolbar wrapper*/}
                      <div className="d-flex align-items-stretch flex-shrink-0">
                        {/*begin::User menu*/}
                        <div className="d-flex align-items-center ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
                          {/*begin::Menu wrapper*/}
                          <div className="cursor-pointer symbol symbol-30px symbol-md-40px" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                            <img src="assets/images/usuario.jpg" alt="user" />
                          </div>
                          {/*end::Menu wrapper*/}
                        </div>
                        {/*end::User menu*/}
                      </div>
                      {/*end::Toolbar wrapper*/}
                    </div>
                    {/*end::Wrapper*/}
                  </div>
                  {/*end::Container*/}
                </div>
                {/*end::Header*/}
                {/*begin::Content*/}
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                  {children}
                </div>

                {/*end::Content*/}

              </div>
              {/*end::Wrapper*/}
            </div>
            {/*end::Page*/}
          </div>
          {/*end::Root*/}
          {/*begin::Drawers*/}\
          {/*end::Drawers*/}
          {/*end::Main*/}
          {/*end::Engage drawers*/}
          {/*begin::Scrolltop*/}
          <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
            {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                <rect opacity="0.5" x={13} y={6} width={13} height={2} rx={1} transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </div>
          {/*end::Scrolltop*/}

        </div>

        {this.state.collapsed == 'on' &&

          <div onClick={e => this.backDropClick()} style={{ zIndex: 109 }} className="drawer-overlay" />
        }
      </>
    )
  }
};
export default index;