import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";

import ReactImageVideoLightbox from "react-image-video-lightbox";

const AccountSettings = () => {
  useEffect(() => {
    document.title = "Minha conta  | 2Close.com"
  }, []);
  return (
    <>

<div className="container m-auto">
        <div className="lg:flex justify-center lg:space-x-10 lg:space-y-0 space-y-5">
          {/* left sidebar*/}
          <div className="space-y-5 flex-shrink-0 lg:w-7/12">
            <div className="capa-header">
              
            </div>
            

            <div className="container pro-container m-auto">
        {/* profile-cover*/}
        <div className="flex lg:flex-row flex-col items-center lg:py-8 lg:space-x-8">
          <div>
            <div className="bg-gradient-to-tr from-yellow-600 to-pink-600 p-1 rounded-full m-0.5 mr-2  w-56 h-56 relative overflow-hidden uk-transition-toggle">  
              <img src="assets/images/avatars/avatar-7.jpg" className="bg-gray-200 border-4 border-white rounded-full w-full h-full dark:border-gray-900" />
              <div className="absolute -bottom-3 custom-overly1 flex justify-center pt-4 pb-7 space-x-3 text-2xl text-white uk-transition-slide-bottom-medium w-full">
                <a href="#" className="hover:text-white">
                  <i className="uil-camera" />
                </a>
                <a href="#" className="hover:text-white">
                  <i className="uil-crop-alt" />
                </a>
              </div>
            </div>
          </div>
          <div className="lg:w/8/12 flex-1 flex flex-col lg:items-start items-center"> 
            <h2 className="font-semibold lg:text-2xl text-lg mb-2"> Stella Jonathan</h2>
            <p className="lg:text-left mb-2 text-center  dark:text-gray-100"> Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet 
              doming id quod mazim placerat facer possim assum</p>
            <div className="flex font-semibold mb-3 space-x-2  dark:text-gray-10">
              <a href="#">Travailing</a> , <a href="#">Sports</a>  , <a href="#">Movies</a> 
            </div>
            <div className="capitalize flex font-semibold space-x-3 text-center text-sm my-2">
              <a href="#" className="bg-gray-300 shadow-sm p-2 px-6 rounded-md dark:bg-gray-700"> Add friend</a>
              <a href="#" className="bg-pink-500 shadow-sm p-2 pink-500 px-6 rounded-md text-white hover:text-white hover:bg-pink-600"> Send message</a>
              <div>
                <a href="#" className="bg-gray-300 flex h-12 h-full items-center justify-center rounded-full text-xl w-9 dark:bg-gray-700"> 
                  <i className="icon-feather-chevron-down" /> 
                </a>
                <div className="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden text-base dark:bg-gray-900" uk-drop="mode: click">
                  <ul className="space-y-1">
                    <li> 
                      <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-700">
                        <i className="uil-user-minus mr-2" />Unfriend
                      </a> 
                    </li>
                    <li> 
                      <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-700">
                        <i className="uil-eye-slash  mr-2" />Hide Your Story
                      </a> 
                    </li>
                    <li> 
                      <a href="#" className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-700">
                        <i className="uil-share-alt mr-2" /> Share This Profile
                      </a> 
                    </li>
                    <li>
                      <hr className="-mx-2 my-2  dark:border-gray-700" />
                    </li>
                    <li> 
                      <a href="#" className="flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600">
                        <i className="uil-stop-circle mr-2" /> Block
                      </a> 
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="divide-gray-300 divide-transparent divide-x grid grid-cols-3 lg:text-left lg:text-lg mt-3 text-center w-full dark:text-gray-100">
              <div className="flex lg:flex-row flex-col"> 120k <strong className="lg:pl-2">Posts</strong></div>
              <div className="lg:pl-4 flex lg:flex-row flex-col"> 420k <strong className="lg:pl-2">Followers</strong></div>
              <div className="lg:pl-4 flex lg:flex-row flex-col"> 530k <strong className="lg:pl-2">Following</strong></div>
            </div>
          </div>
          <div className="w-20" />
        </div>
        <h1 className="lg:text-2xl text-lg font-extrabold leading-none text-gray-900 tracking-tight mt-8"> Highths </h1>
        <div className="my-6 grid lg:grid-cols-5 grid-cols-3 gap-2 hover:text-yellow-700 uk-link-reset">
          <a href="#">
            <div className="bg-gray-100 border-4 border-dashed flex flex-col h-full items-center justify-center relative rounded-2xl w-full"> 
              <i className="text-4xl uil-plus-circle" /> <span> Add new </span>
            </div>
          </a>
          <a href="#story-modal" uk-toggle>
            <img src="assets/images/avatars/avatar-lg-1.jpg" alt="" className="w-full lg:h-60 h-40 rounded-md object-cover" />
          </a>
          <a href="#story-modal" uk-toggle>
            <img src="assets/images/post/img2.jpg" alt="" className="w-full lg:h-60 h-40 rounded-md object-cover" />
          </a>
          <a href="#story-modal" uk-toggle>
            <img src="assets/images/post/img7.jpg" alt="" className="w-full lg:h-60 h-40 rounded-md object-cover uk-visible@s" />
          </a>
        </div>
        <div className="flex items-center justify-between mt-8 space-x-3">
          <h1 className="flex-1 font-extrabold leading-none lg:text-2xl text-lg text-gray-900 tracking-tight uk-heading-line"><span>Explore</span></h1>
          <div className="bg-white border border-2 border-gray-300 divide-gray-300 divide-x flex rounded-md shadow-sm dark:bg-gray-100">
            <a href="#" className="bg-gray300 flex h-10 items-center justify-center  w-10" data-tippy-placement="top" title="Grid view"> <i className="uil-apps" /></a>
            <a href="#" className="flex h-10 items-center justify-center w-10" data-tippy-placement="top" title="List view"> <i className="uil-list-ul" /></a>                        
          </div>
        </div>
        <div className="my-6 grid lg:grid-cols-4 grid-cols-2 gap-1.5 hover:text-yellow-700 uk-link-reset">
          <div>
            <div className="bg-red-500 max-w-full lg:h-64 h-40 rounded-md relative overflow-hidden uk-transition-toggle" tabIndex={0}> 
              <img src="assets/images/avatars/avatar-lg-1.jpg" className="w-full h-full absolute object-cover inset-0" />
              <div className="absolute bg-black bg-opacity-40 bottom-0 flex h-full items-center justify-center space-x-5 text-lg text-white uk-transition-scale-up w-full">   
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="heart" className="mr-1" /> 150 </a>
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="chatbubble-ellipses" className="mr-1" /> 30 </a>
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="pricetags" className="mr-1" /> 12</a>
              </div>
            </div>
          </div>
          <div>
            <div className="bg-red-500 max-w-full lg:h-64 h-40 rounded-md relative overflow-hidden uk-transition-toggle" tabIndex={0}> 
              <img src="assets/images/post/img1.jpg" className="w-full h-full absolute object-cover inset-0" />
              <div className="absolute bg-black bg-opacity-40 bottom-0 flex h-full items-center justify-center space-x-5 text-lg text-white uk-transition-scale-up w-full">   
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="heart" className="mr-1" /> 150 </a>
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="chatbubble-ellipses" className="mr-1" /> 30 </a>
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="pricetags" className="mr-1" /> 12</a>
              </div>
            </div>
          </div>
          <div>
            <div className="bg-red-500 max-w-full lg:h-64 h-40 rounded-md relative overflow-hidden uk-transition-toggle" tabIndex={0}> 
              <img src="assets/images/post/img2.jpg" className="w-full h-full absolute object-cover inset-0" />
              <div className="absolute bg-black bg-opacity-40 bottom-0 flex h-full items-center justify-center space-x-5 text-lg text-white uk-transition-scale-up w-full">   
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="heart" className="mr-1" /> 150 </a>
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="chatbubble-ellipses" className="mr-1" /> 30 </a>
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="pricetags" className="mr-1" /> 12</a>
              </div>
            </div>
          </div>
          <div>
            <div className="bg-red-500 max-w-full lg:h-64 h-40 rounded-md relative overflow-hidden uk-transition-toggle" tabIndex={0}> 
              <img src="assets/images/post/img3.jpg" className="w-full h-full absolute object-cover inset-0" />
              <div className="absolute bg-black bg-opacity-40 bottom-0 flex h-full items-center justify-center space-x-5 text-lg text-white uk-transition-scale-up w-full">   
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="heart" className="mr-1" /> 150 </a>
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="chatbubble-ellipses" className="mr-1" /> 30 </a>
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="pricetags" className="mr-1" /> 12</a>
              </div>
            </div>
          </div>
          <div>
            <div className="bg-red-500 max-w-full lg:h-64 h-40 rounded-md relative overflow-hidden uk-transition-toggle" tabIndex={0}> 
              <img src="assets/images/post/img4.jpg" className="w-full h-full absolute object-cover inset-0" />
              <div className="absolute bg-black bg-opacity-40 bottom-0 flex h-full items-center justify-center space-x-5 text-lg text-white uk-transition-scale-up w-full">   
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="heart" className="mr-1" /> 150 </a>
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="chatbubble-ellipses" className="mr-1" /> 30 </a>
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="pricetags" className="mr-1" /> 12</a>
              </div>
            </div>
          </div>
          <div>
            <div className="bg-red-500 max-w-full lg:h-64 h-40 rounded-md relative overflow-hidden uk-transition-toggle" tabIndex={0}> 
              <img src="assets/images/post/img5.jpg" className="w-full h-full absolute object-cover inset-0" />
              <div className="absolute bg-black bg-opacity-40 bottom-0 flex h-full items-center justify-center space-x-5 text-lg text-white uk-transition-scale-up w-full">   
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="heart" className="mr-1" /> 150 </a>
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="chatbubble-ellipses" className="mr-1" /> 30 </a>
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="pricetags" className="mr-1" /> 12</a>
              </div>
            </div>
          </div>
          <div>
            <div className="bg-red-500 max-w-full lg:h-64 h-40 rounded-md relative overflow-hidden uk-transition-toggle" tabIndex={0}> 
              <img src="assets/images/avatars/avatar-1.jpg" className="w-full h-full absolute object-cover inset-0" />
              <div className="absolute bg-black bg-opacity-40 bottom-0 flex h-full items-center justify-center space-x-5 text-lg text-white uk-transition-scale-up w-full">   
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="heart" className="mr-1" /> 150 </a>
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="chatbubble-ellipses" className="mr-1" /> 30 </a>
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="pricetags" className="mr-1" /> 12</a>
              </div>
            </div>
          </div>
          <div>
            <div className="bg-red-500 max-w-full lg:h-64 h-40 rounded-md relative overflow-hidden uk-transition-toggle" tabIndex={0}> 
              <img src="assets/images/avatars/avatar-6.jpg" className="w-full h-full absolute object-cover inset-0" />
              <div className="absolute bg-black bg-opacity-40 bottom-0 flex h-full items-center justify-center space-x-5 text-lg text-white uk-transition-scale-up w-full">   
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="heart" className="mr-1" /> 150 </a>
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="chatbubble-ellipses" className="mr-1" /> 30 </a>
                <a href="#story-modal" uk-toggle className="flex items-center"> <ion-icon name="pricetags" className="mr-1" /> 12</a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-6">
          <a href="#" className="bg-white dark:bg-gray-900 font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white"> Load more ..</a>
        </div>
      </div>
          </div>
          {/* right sidebar*/}
          <div className="lg:w-5/12">
            <div className="bg-white dark:bg-gray-900 shadow-md rounded-md overflow-hidden">
              <div className="bg-gray-50 dark:bg-gray-800 border-b border-gray-100 flex items-baseline justify-between py-4 px-6 dark:border-gray-800">
                <h2 className="font-semibold text-lg">Sugestões</h2>
                <a href="#"> Atualizar</a>
              </div>
              <div className="divide-gray-300 divide-gray-50 divide-opacity-50 divide-y px-4 dark:divide-gray-800 dark:text-gray-100">
                <div className="flex items-center justify-between py-3">
                  <div className="flex flex-1 items-center space-x-4">
                    <a href="profile.html">
                      <img src="assets/images/avatars/avatar-2.jpg" className="bg-gray-200 rounded-full w-10 h-10" />
                    </a>
                    <div className="flex flex-col">
                      <span className="block capitalize font-semibold"> Johnson smith </span>
                      <span className="block capitalize text-sm"> Australia </span>
                    </div>
                  </div>
                  <a href="#" className="border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-pink-600 hover:text-white hover:border-pink-600 dark:border-gray-800"> Follow </a>
                </div>
                <div className="flex items-center justify-between py-3">
                  <div className="flex flex-1 items-center space-x-4">
                    <a href="profile.html">
                      <img src="assets/images/avatars/avatar-1.jpg" className="bg-gray-200 rounded-full w-10 h-10" />
                    </a>
                    <div className="flex flex-col">
                      <span className="block capitalize font-semibold"> James Lewis </span>
                      <span className="block capitalize text-sm"> Istanbul </span>
                    </div>
                  </div>
                  <a href="#" className="border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-pink-600 hover:text-white hover:border-pink-600 dark:border-gray-800"> Follow </a>
                </div>
                <div className="flex items-center justify-between py-3">
                  <div className="flex flex-1 items-center space-x-4">
                    <a href="profile.html">
                      <img src="assets/images/avatars/avatar-5.jpg" className="bg-gray-200 rounded-full w-10 h-10" />
                    </a>
                    <div className="flex flex-col">
                      <span className="block capitalize font-semibold"> John Michael </span>
                      <span className="block capitalize text-sm"> New York </span>
                    </div>
                  </div>
                  <a href="#" className="border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-pink-600 hover:text-white hover:border-pink-600 dark:border-gray-800"> Follow </a>
                </div>
                <div className="flex items-center justify-between py-3">
                  <div className="flex flex-1 items-center space-x-4">
                    <a href="profile.html">
                      <img src="assets/images/avatars/avatar-7.jpg" className="bg-gray-200 rounded-full w-10 h-10" />
                    </a>
                    <div className="flex flex-col">
                      <span className="block capitalize font-semibold"> Monroe Parker </span>
                      <span className="block capitalize text-sm"> Yeman </span>
                    </div>
                  </div>
                  <a href="#" className="border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-pink-600 hover:text-white hover:border-pink-600 dark:border-gray-800"> Follow </a>
                </div>
              </div>
            </div>
            <div className="mt-5" uk-sticky="offset:28; bottom:true ; media @m">
              <div className="bg-white dark:bg-gray-900 shadow-md rounded-md overflow-hidden">
                <div className="bg-gray-50 border-b border-gray-100 flex items-baseline justify-between py-4 px-6 dark:bg-gray-800 dark:border-gray-700">
                  <h2 className="font-semibold text-lg">Latest</h2>
                  <a href="explore.html"> See all</a>
                </div>
                <div className="grid grid-cols-2 gap-2 p-3 uk-link-reset">
                  <div className="bg-red-500 max-w-full h-32 rounded-lg relative overflow-hidden uk-transition-toggle"> 
                    <a href="#story-modal" uk-toggle>
                      <img src="assets/images/post/img2.jpg" className="w-full h-full absolute object-cover inset-0" />
                    </a>
                    <div className="flex flex-1 justify-around items-center absolute bottom-0 w-full p-2 text-white custom-overly1 uk-transition-slide-bottom-medium">   
                      <a href="#"> <i className="uil-heart" /> 150 </a>
                      <a href="#"> <i className="uil-heart" /> 30 </a>
                    </div>
                  </div>
                  <div className="bg-red-500 max-w-full h-40 rounded-lg relative overflow-hidden uk-transition-toggle"> 
                    <a href="#story-modal" uk-toggle>
                      <img src="assets/images/post/img7.jpg" className="w-full h-full absolute object-cover inset-0" />
                    </a>
                    <div className="flex flex-1 justify-around items-center absolute bottom-0 w-full p-2 text-white custom-overly1 uk-transition-slide-bottom-medium">   
                      <a href="#"> <i className="uil-heart" /> 150 </a>
                      <a href="#"> <i className="uil-heart" /> 30 </a>
                    </div>
                  </div>                             
                  <div className="bg-red-500 max-w-full h-40 -mt-8 rounded-lg relative overflow-hidden uk-transition-toggle"> 
                    <a href="#story-modal" uk-toggle>
                      <img src="assets/images/post/img5.jpg" className="w-full h-full absolute object-cover inset-0" />
                    </a>
                    <div className="flex flex-1 justify-around  items-center absolute bottom-0 w-full p-2 text-white custom-overly1 uk-transition-slide-bottom-medium">   
                      <a href="#"> <i className="uil-heart" /> 150 </a>
                      <a href="#"> <i className="uil-heart" /> 30 </a>
                    </div>
                  </div>
                  <div className="bg-red-500 max-w-full h-32 rounded-lg relative overflow-hidden uk-transition-toggle"> 
                    <a href="#story-modal" uk-toggle>
                      <img src="assets/images/post/img3.jpg" className="w-full h-full absolute object-cover inset-0" />
                    </a>
                    <div className="flex flex-1 justify-around  items-center absolute bottom-0 w-full p-2 text-white custom-overly1 uk-transition-slide-bottom-medium">   
                      <a href="#"> <i className="uil-heart" /> 150 </a>
                      <a href="#"> <i className="uil-heart" /> 30 </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>               
      </div>
    </>
  );

}



export default withRouter(AccountSettings);                  