/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import classNames from 'classnames'
import Modal from "react-bootstrap/Modal";
import LoadingOverlay from 'react-loading-overlay';

import Select from 'react-select'
import Api from '../../../services/api';
import Swal from 'sweetalert2';
import { useToasts } from 'react-toast-notifications';

import { Headerer } from '../../../components/headerer'
import { Paginator } from '../../../components/paginator'
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';

const UsuariosGerenciar = () => {

    const { addToast } = useToasts();
    const stsOpt = [{ value: 'ATIVA', label: "Ativa" }, { value: 'INATIVA', label: "Inativa" }]
    const [modalIsOpen, setIsOpen] = useState(false);

    useEffect(() => {
        getUsuarios();
    }, []);

    const [formUsuarios, setFormUsuarios] = useState({});
    const [actPage, setActPage] = useState();
    const [totPage, setTotPage] = useState();
    const [changePageReferreds, setChangePageReferreds] = useState(null);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState({ field: {} });
    const [usuarios, setUsuarios] = useState([]);
    
    function getUsuarios(page) {
        setLoading(true);
        Api.post("usuarios/list?page=" + page, search).then((rps) => {
            setLoading(false);

            if (rps.data.status !== true) {
                Swal.fire({
                    title: "Erro!",
                    icon: "danger",
                    html: rps.data.message,
                    showCloseButton: true,
                    showCancelButton: false,
                });
                return;
            }

        setUsuarios(rps.data.obj);
        setTotPage(rps.data.last_page);
        setActPage(rps.data.current_page);
        })
    }

    useEffect(() => {
        if (changePageReferreds === null)
            return;
        getUsuarios(changePageReferreds);
    }, [changePageReferreds])

    useEffect(() => {
        setActPage(1);
        getUsuarios(1);
    }, [search])
    /*Carregamento dos cards em si - final */

    function editar(row) {
        var r = JSON.parse(JSON.stringify(row));
        setFormUsuarios(r);
        setIsOpen(true);
    }

    function adicionar() {
        setFormUsuarios({});
        setIsOpen(true);
    }

    function handleFechaModal() {
        setIsOpen(false);
    }

    function salvar() {
        Api.post("/usuarios/save", formUsuarios).then((rps) => {

            if (rps.data.status === true) {
                addToast(rps.data.message, {
                    appearance: "success",
                    autoDismiss: true,
                    autoDismissTimeout: 2000
                });
            } else {
                Swal.fire({
                    title: "Erro!",
                    icon: "error",
                    html: Object.values(rps.data.errors).map((mensagem) => `<p>${mensagem}</p>`).join(''),
                    showCloseButton: true,
                    showCancelButton: false,
                });
            }
        })
        setIsOpen(false);
        getUsuarios();
    }

    return (
        <>
            {/*begin::Post*/}
            <div className="d-flex flex-column-fluid">
                {/*begin::Container*/}
                <div className="container">


                    <div id="kt_app_toolbar" className="app-toolbar  pt-5">
                        <div id="kt_app_toolbar_container" className="app-container d-flex flex-stack ">
                            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                                    Acesso Admin
                                </h1>
                                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                    <li className="breadcrumb-item text-muted">
                                        <Link className="text-muted text-hover-primary" to="/dashboard">
                                            Home
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <span className="bullet bg-gray-400 w-5px h-2px" />
                                    </li>
                                    <li className="breadcrumb-item text-muted">
                                        Dashboards                                          
                                    </li>
                                </ul>
                            </div>
                            <div className="d-flex align-items-center gap-2 gap-lg-3">
                                <button className='btn btn-sm btn-primary' onClick={e => { adicionar() }}><i className="fas fa-plus" />Adicionar</button>
                            </div>
                        </div>
                    </div>

                    <div className="card card-custom gutter-b example example-compact">
                        <div className="mt-5">

                            <LoadingOverlay
                                active={loading}
                                spinner
                            >

                                <table className="table table-striped" id="kt_table_users">

                                    <thead className="bg-light-primary thead-inputs">
                                        <Headerer
                                            columns={[
                                                { name: 'ID', id: 'id', searchable: true, sortable: true },
                                                { name: 'Nome', id: 'name', searchable: true, sortable: true },
                                                { name: 'E-mail', id: 'email', searchable: true, sortable: true },
                                                {
                                                    name: 'Tipo', id: 'type', searchable: false, sortable: false,
                                                    selects: [
                                                        { name: "ADMIN", value: 'ADMIN' },
                                                    ]
                                                },
                                                { name: 'Ações', id: 'action', searchable: false, sortable: false }
                                            ]}
                                            setSearchOrig={setSearch}
                                            searchOrig={search}
                                        />
                                    </thead>

                                    <tbody className="text-gray-600 fw-bold">
                                        {usuarios.map((usu) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td className='text-center max-width-id'>{usu.id}</td>
                                                        <td>{usu.name}</td>
                                                        <td>{usu.email}</td>
                                                        <td className='text-center'>
                                                            {usu.type == "ADMIN" &&
                                                                <span class="badge badge-success mr-2">ADMIN</span>}
                                                        </td>
                                                        <td className='text-center'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle class="btn-drop btn btn-primary">
                                                                    <i class="flaticon-more"></i>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item>
                                                                        <a class="dropdown-item" onClick={e => { e.preventDefault(); editar(usu) }} >Editar</a>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </td>
                                                    </tr>

                                                </>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </LoadingOverlay>

                            <Paginator maxPages={totPage} actPage={actPage} setNextPage={setChangePageReferreds} loading={loading} />

                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Card*/}


                    {/*end::Post*/}
                    <Modal
                        enforceFocus={false}
                        size={"lg"}
                        show={modalIsOpen}
                        onHide={() => handleFechaModal()}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Acesso Admin</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                           
                            <div className="row ml-3 mr-3">
                                <div className="form-group col-md-12">
                                    <label>Nome <span class="text-danger">*</span></label>
                                    <input className="form-control"
                                        value={formUsuarios?.name}
                                        onChange={(e) => { setFormUsuarios({ ...formUsuarios, name: e.target.value }) }} />
                                </div>
                            </div>

                            <div className="row ml-3 mr-3 mt-3">
                                <div className="form-group col-md-12">
                                    <label>E-mail <span class="text-danger">*</span></label>
                                    <input className="form-control"
                                        value={formUsuarios?.email}
                                        onChange={(e) => { setFormUsuarios({ ...formUsuarios, email: e.target.value }) }} />
                                </div>
                            </div>

                            <div className="row ml-3 mr-3 mt-3">
                                <div className="form-group col-md-6">
                                    <label>Senha <span class="text-danger">*</span></label>
                                    <input className="form-control"
                                        type="password"
                                        onChange={(e) => { setFormUsuarios({ ...formUsuarios, senha: e.target.value }) }} />
                                </div>

                                <div className="form-group col-md-6">
                                    <label>Confirmação de Senha <span class="text-danger">*</span></label>
                                    <input className="form-control"
                                        type="password"
                                        onChange={(e) => { setFormUsuarios({ ...formUsuarios, senha2: e.target.value }) }} />
                                </div>
                            </div>

                        </Modal.Body>

                        <Modal.Footer>
                            <button onClick={() => handleFechaModal()} type="button" class="btn btn-light" data-bs-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
                            <button onClick={e => { e.preventDefault(); salvar() }} type="button" class="btn btn-success"><i className="fas fa-save" />Salvar</button>
                        </Modal.Footer>
                    </Modal>



                    
                </div>
            </div>
        </>
    );

}

export default withRouter(UsuariosGerenciar);                  