import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import Modal from "react-bootstrap/Modal";

import DateRangePicker from 'rsuite/DateRangePicker';
import "rsuite/dist/rsuite-rtl.css";

const Dashboard = () => {


  useEffect(() => {
    //document.title = ""
  }, []);


  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);


  function handleFechaModal() {
    setIsOpen(false);
  }

  function abreModal(){
    console.log("abre model");
    setIsOpen(true);
  }


  return (
    <>
  {/*begin::Toolbar*/}
  <div className="toolbar" id="kt_toolbar">
    {/*begin::Container*/}
    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
      {/*begin::Page title*/}
      <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
        {/*begin::Title*/}
        <h1 className="d-flex text-dark fw-bolder fs-3 align-items-center my-1">Dashboard</h1>
      </div>
      {/*end::Page title*/}
      {/* <p>
        <DateRangePicker
        showToolbar
        showTodayButton
        openTo={"month"}
        showDaysOutsideCurrentMonth
        startText="Check-in"
        endText="Check-out"
        onChange={(date) => console.log(date)}
        
      />
      </p> */}


    </div>
    {/*end::Container*/}
  </div>
  {/*end::Toolbar*/}
  {/*begin::Post*/}
  <div className="post d-flex flex-column-fluid" id="kt_post">
    {/*begin::Container*/}
    <div id="kt_content_container" className="container-xxl">
      {/*begin::Card*/}
      <div className="card">
        <div className="card-body py-4">

<p className="mt-10">Em breve...</p>
          
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Card*/}

  {/*end::Post*/}
  <Modal
        size={"lg"}
        show={modalIsOpen}
        onHide={() => handleFechaModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Categoria</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <form id="kt_modal_new_target_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#">
        {/*begin::Input group*/}
        <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
          {/*begin::Label*/}
          <label className="d-flex align-items-center fs-6 fw-bold mb-2">
            <span className="required">Target Title</span>
            <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title data-bs-original-title="Specify a target name for future usage and reference" aria-label="Specify a target name for future usage and reference" />
          </label>
          {/*end::Label*/}
          <input type="text" className="form-control form-control-solid" placeholder="Enter Target Title" name="target_title" />
          <div className="fv-plugins-message-container invalid-feedback" /></div>
        {/*end::Input group*/}
        {/*begin::Input group*/}
        <div className="row g-9 mb-8">
          {/*begin::Col*/}
          <div className="col-md-12 fv-row fv-plugins-icon-container">
            <label className="required fs-6 fw-bold mb-2">Assign</label>
            <select className="form-select form-select-solid select2-hidden-accessible" data-control="select2" data-hide-search="true" data-placeholder="Select a Team Member" name="target_assign" data-select2-id="select2-data-16-27o1" tabIndex={-1} aria-hidden="true">
              <option value data-select2-id="select2-data-18-weke">Select user...</option>
              <option value={1}>Karina Clark</option>
              <option value={2}>Robert Doe</option>
              <option value={3}>Niel Owen</option>
              <option value={4}>Olivia Wild</option>
              <option value={5}>Sean Bean</option>
            </select><span className="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-17-jvqu" style={{width: '100%'}}><span className="selection"><span className="select2-selection select2-selection--single form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-disabled="false" aria-labelledby="select2-target_assign-q0-container" aria-controls="select2-target_assign-q0-container"><span className="select2-selection__rendered" id="select2-target_assign-q0-container" role="textbox" aria-readonly="true" title="Select a Team Member"><span className="select2-selection__placeholder">Select a Team Member</span></span><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span>
            <div className="fv-plugins-message-container invalid-feedback" /></div>
          {/*end::Col*/}
        </div>
        {/*end::Input group*/}
        {/*begin::Input group*/}
        <div className="d-flex flex-stack mb-8">
          {/*begin::Label*/}
          <div className="me-5">
            <label className="fs-6 fw-bold">Adding Users by Team Members</label>
            <div className="fs-7 fw-bold text-muted">If you need more info, please check budget planning</div>
          </div>
          {/*end::Label*/}
          {/*begin::Switch*/}
          <label className="form-check form-switch form-check-custom form-check-solid">
            <input className="form-check-input" type="checkbox" defaultValue={1} defaultChecked="checked" />
            <span className="form-check-label fw-bold text-muted">Allowed</span>
          </label>
          {/*end::Switch*/}
        </div>
        {/*end::Input group*/}
        {/*begin::Input group*/}
        <div className="mb-15 fv-row">
          {/*begin::Wrapper*/}
          <div className="d-flex flex-stack">
            {/*begin::Label*/}
            <div className="fw-bold me-5">
              <label className="fs-6">Notifications</label>
              <div className="fs-7 text-muted">Allow Notifications by Phone or Email</div>
            </div>
            {/*end::Label*/}
            {/*begin::Checkboxes*/}
            <div className="d-flex align-items-center">
              {/*begin::Checkbox*/}
              <label className="form-check form-check-custom form-check-solid me-10">
                <input className="form-check-input h-20px w-20px" type="checkbox" name="communication[]" defaultValue="email" defaultChecked="checked" />
                <span className="form-check-label fw-bold">Email</span>
              </label>
              {/*end::Checkbox*/}
              {/*begin::Checkbox*/}
              <label className="form-check form-check-custom form-check-solid">
                <input className="form-check-input h-20px w-20px" type="checkbox" name="communication[]" defaultValue="phone" />
                <span className="form-check-label fw-bold">Phone</span>
              </label>
              {/*end::Checkbox*/}
            </div>
            {/*end::Checkboxes*/}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Input group*/}
        {/*begin::Actions*/}
        <div className="text-center">
          <button type="reset" id="kt_modal_new_target_cancel" className="btn btn-light me-3">Cancel</button>
          <button type="submit" id="kt_modal_new_target_submit" className="btn btn-primary">
            <span className="indicator-label">Submit</span>
            <span className="indicator-progress">Please wait... 
              <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
          </button>
        </div>
        {/*end::Actions*/}
        <div /></form>

          </Modal.Body>
          </Modal>



          </div>
    {/*end::Container*/}
  </div>
    </>
  );

}



export default withRouter(Dashboard);                  