import classNames from 'classnames';
export function Paginator({ maxPages, actPage, setNextPage, loading}) {

    function paginationGenerate() {
        var pageCount = maxPages;
        /* Page Range Calculation */
        var range = pageRange(actPage, pageCount);
        var start = range.start;
        var end = range.end;

        var pagination = [];
        for (var page_id = start; page_id <= end; page_id++) {
            pagination[pagination.length] = page_id;
        }

        return pagination;
    }

    /* Pagination Navigation */
    function checkPrevious(id) {
        if (id > 1) {
            setPage(id - 1);
        }
    }

    /* Pagination Navigation */
    function checkNext(id) {
        if (actPage < maxPages) {
            setPage(actPage + 1);
        }
    }
    function setPage(page) {
        if(loading===true) return;
        setNextPage(page);
    }

    /* Page Range calculation Method for Pagination */
    function pageRange(page, pageCount) {

        var start = page - 2,
            end = page + 2;

        if (end > pageCount) {
            start -= (end - pageCount);
            end = pageCount;
        }
        if (start <= 0) {
            end += ((start - 1) * (-1));
            start = 1;
        }

        end = end > pageCount ? pageCount : end;

        return {
            start: start,
            end: end
        };
    }


    return (
        <>
            <ul className="pagination d-flex justify-content-center">
                <li className="page-item" onClick={e => { checkPrevious(actPage) }}>
                    <span className="page-link"><i className="fa fa-long-arrow-alt-left" /></span>
                </li>

                {(paginationGenerate().indexOf(1) === -1 )&& <>
                    <li className="page-item" onClick={e => { setPage(1) }}>
                        <span className="page-link">1</span>
                    </li>
                    {maxPages > 1 && 
                        <li className="page-item disabled"><span className="page-link">...</span></li>
                    }
                </>}

                
                {paginationGenerate().map((page) => {
                    return (<>
                        <li className={
                            classNames('page-item ', {
                                'active': page === actPage
                            })} onClick={e => { setPage(page) }}>
                            <span className="page-link">{page}</span>
                        </li>
                    </>)
                })}

                {paginationGenerate().indexOf(maxPages) === -1 && <>
                    {maxPages > 1 && <>

                        <li className="page-item disabled"><span className="page-link">...</span></li>
                    <li className="page-item" onClick={e => { setPage(maxPages) }}>
                        <span className="page-link">{maxPages}</span>
                    </li>
                    </>}
                </>}

                <li className="page-item" onClick={e => { checkNext(actPage) }}>
                    <span className="page-link"><i className="fa fa-long-arrow-alt-right" /></span>
                </li>

            </ul>
        </>
    )
}
