/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useParams, withRouter } from "react-router";
import classNames from 'classnames'
import Modal from "react-bootstrap/Modal";

import Select from 'react-select'
import api from '../../../services/api';
import LoadingOverlay from 'react-loading-overlay';
import { useToasts } from 'react-toast-notifications';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import { Headerer } from '../../../components/headerer'
import { Paginator } from '../../../components/paginator'
import { AprofundamentoTematico } from '../../../components/AprofundamentoTematico';

import { ButtonLoading } from '../../../components/ButtonLoading';
import ReactQuill from 'react-quill';

const AcademicoCards = () => {
  const { addToast } = useToasts();
  const { id_tema } = useParams();

  useEffect(() => {
    getDisciplinas();
  }, []);


  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [tabSelected, setTabSelected] = React.useState('cards');
  const [tabSelectedTipo, setTabSelectedTipo] = React.useState('omissao');
  const [loading, setLoading] = useState(false);


  function handleFechaModal() {
    setIsOpen(false);
  }

  const defaultCard = { tipo: 'OMISSAO', card: '', id_materia: null, id_disciplina: null, id_tema: null, tags: [] };
  const [card, setCard] = useState(defaultCard);


  const [disciplinas, setDisciplinas] = useState([]);
  function getDisciplinas() {
    api.get("/disciplinas").then((rps) => {
      if (rps.data.status !== true) {
        alert(rps.data.message);
        return;
      }
      var nDis = [];
      rps.data.obj.map(d => {
        nDis[nDis.length] = { value: d.id, label: d.nome }
      })
      setDisciplinas(nDis);
    })
  }

  const [loadingMaterias, setLoadingMaterias] = useState(false);
  const [materias, setMaterias] = useState([]);
  function getMaterias(id, set = true) {
    setLoadingMaterias(true);
    getTags(id);
    api.get("/materias/" + id).then((rps) => {
      setLoadingMaterias(true);
      if (set) setCard({ ...card, id_disciplina: id, id_materia: null, id_tema: null })
      if (rps.data.status !== true) {
        alert(rps.data.message);
        return;
      }
      var nMat = [];
      rps.data.obj.map(d => {
        nMat[nMat.length] = { value: d.id, label: d.nome }
      })
      setMaterias(nMat);
      setLoadingMaterias(false);
    })
  }

  
  const [loadingTags, setLoadingTags] = useState(false);
  const [tags, setTags] = useState([]);
  function getTags(id) {
    setLoadingTags(true);
    api.get("/tags/" + id).then((rps) => {
      setLoadingTags(true);
      if (rps.data.status !== true) {
        alert(rps.data.message);
        return;
      }
      console.log(rps.data.obj);
      var nTag = [];
      rps.data.obj.map(d => {
        nTag[nTag.length] = { value: d.id, label: d.nome }
      })
      setTags(nTag);
      if (card.tags !== undefined || card.tags !== null) {
        selectTags(card.tags);
      }
    })
  }



  const [loadingTemas, setLoadingTemas] = useState(false);
  const [temas, setTemas] = useState([]);
  function getTemas(id, set = true) {
    setLoadingTemas(true);
    getSubtemas(id);
    api.get("/temas/" + id).then((rps) => {
      setLoadingTemas(true);
      if (set) setCard({ ...card, id_materia: id, id_tema: null })
      if (rps.data.status !== true) {
        alert(rps.data.message);
        return;
      }
      var nTem = [];
      rps.data.obj.map(d => {
        nTem[nTem.length] = { value: d.id, label: d.nome }
      })
      setTemas(nTem);
      setLoadingTemas(false);
    })
  }


  const [loadingSubtemas, setLoadingSubtemas] = useState(false);
  const [subtemas, setSubtemas] = useState([]);
  function getSubtemas(id, set = true) {
    setLoadingSubtemas(true);
    api.get('/subtemas/' + id).then((rps) => {
      setLoadingSubtemas(false);

      if (rps.data.status !== true) {
        alert(rps.data.message);
        return;
      }

      var nTem = [];
      rps.data.obj.map(d => {
        nTem[nTem.length] = { value: d.id, label: d.nome }
      })
      setSubtemas(nTem);
    })
  }


  const [loadingSaveCard, setLoadingSaveCard] = useState(false);
  function salvarCard() {
    setLoadingSaveCard(true);
    api.post("cards", card).then((rps) => {
      setLoadingSaveCard(false);
      if (rps.data.status === true) {
        addToast(rps.data.message, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000
        });
        setCard({ ...card, id: rps.data.id });
      } else {
        Swal.fire(rps.data.message);
      }
    })  
      getUsuarios(1);
      setIsOpen(false);    
  }

  function addTag(tipo, escolhas = null) {

    if (tipo === 'OMISSAO') {
      Swal.fire({
        title: "Digite a omissão",
        input: 'text',
        showCancelButton: true
      }).then((result) => {
        if (result.value) {
          montaTag(tipo, null, result.value)
        }
      });
    } else if (tipo === 'ESCOLHA') {
      Swal.fire({
        title: 'Escolha a correta',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: escolhas[0],
        denyButtonText: escolhas[1],
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          montaTag(tipo, escolhas, escolhas[0])
        } else if (result.isDenied) {
          montaTag(tipo, escolhas, escolhas[1])
        }
      })
    }
  }
  function montaTag(tipo, escolhas, escolha) {
    var str = '';
    if (tipo === 'OMISSAO') {
      str = '{{c1::' + escolha + '}}';
    } else if (tipo === 'ESCOLHA') {
      var escs = escolhas.join("|")
      str = '{{c2::' + escs + '::' + escolha + '}}';
    }

    setCard({ ...card, card: card.card + " " + str })
  }

  const [tagsSel, setTagsSel] = useState([]);
  function handleTags(val) {
    var tagsSelected = [];
    val.map(v => {
      tagsSelected[tagsSelected.length] = v.value;
    })
    setTagsSel(val);
    setCard({ ...card, tags: tagsSelected });
  }
  function selectTags(tags2) {
    if (tags2 === null || tags2 === undefined) tags2 = [];
    var tagsSelected = [];
    tags2.map(tag => {
      tags.map(a => {
        if (a.value === tag)
          tagsSelected[tagsSelected] = a;
      })
    });

    console.log(tagsSelected);
  }

  function eita() {
    if (id_tema !== null && id_tema !== undefined) {
      return { field: { id_tema: id_tema } };
    } else {
      return { field: {} };
    }
  }

  /*Carregamento dos cards em si - inicio */
  const [users, setUsers] = useState([]);
  const [actPage, setActPage] = useState();
  const [totPage, setTotPage] = useState();
  const [changePageReferreds, setChangePageReferreds] = useState(null);
  const [searchUsers, setSearchUsers] = useState(eita());
  function getUsuarios(page) {
    setLoading(true);
    var searchUsersModified = JSON.parse(JSON.stringify(searchUsers));
    searchPai.map(([key, val]) => {
      console.log(key, val);
    });


    api.post("/cards/list?page=" + page, searchUsers).then((rps) => {
      setLoading(false);

      if (rps.data.status !== true) {
        //Swal.fire(rps.data.message);
        //return;
      }
      setUsers(rps.data.data);
      setTotPage(rps.data.last_page);
      setActPage(rps.data.current_page);
    })
  }
  useEffect(() => {
    if (changePageReferreds === null)
      return;
    getUsuarios(changePageReferreds);
  }, [changePageReferreds])

  useEffect(() => {
    setActPage(1);
    getUsuarios(1);
  }, [searchUsers])
  /*Carregamento dos cards em si - final */

  function editar(card) {
    setCard(card);
    setIsOpen(true);
    getMaterias(card.id_disciplina, false);
    getTemas(card.id_materia, false);
    getTags(card.id_disciplina);

    getVinculados(1, card);
  }

  function excluir(card) {
    var data = JSON.parse(JSON.stringify(card));

    setLoading(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja excluir o Card?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {

            api.post('/cards/delete', data).then(rps => {
                if (rps.data.status == true) {
                    addToast(rps.data.message, {
                        appearance: "success",
                        autoDismiss: true,
                        autoDismissTimeout: 2000
                    });
                    getUsuarios();
                }
            })
        }
        setLoading(false);
    })
  }

  function newCard(card) {
//    setCard(defaultCard);
    getDisciplinas();
    sessionStorage.setItem('atualDisciplina', sessionStorage.getItem('idMateria'));
    sessionStorage.setItem('atualMateria', sessionStorage.getItem('idTema'));
    sessionStorage.setItem('atualTema', sessionStorage.getItem('idCard'));
    getMaterias(sessionStorage.getItem('atualDisciplina'), false);
    getTemas(sessionStorage.getItem('atualMateria'), false);
    setCard({ tipo: 'OMISSAO', card: '', id_materia: sessionStorage.getItem('atualMateria'), 
      id_disciplina: sessionStorage.getItem('atualDisciplina'), id_tema: sessionStorage.getItem('atualTema'), tags: [] });
    setIsOpen(true);
  }

  /* Cards vinculados - inicio */
  const [vinculados, setVinculados] = useState([]);
  const [actVinPage, setActPVinage] = useState();
  const [totVinPage, setTotVinPage] = useState();
  const [changePageVin, setChangePageVin] = useState(null);
  const [loadingVin, setLoadingVin] = useState(false);
  const [searchVin, setSearchVin] = useState({ field: {} });

  function getVinculados(page, card) {
    api.get("/cards/vinculados/" + card.id + "?page=" + page).then((rps) => {
      if (rps.data.status !== true) {
        Swal.fire(rps.data.message);
        return;
      }
      setVinculados(rps.data.data);
      setTotVinPage(rps.data.last_page);
      setActPVinage(rps.data.current_page);
    })
  }

  useEffect(() => {
    if (changePageVin === null)
      return;
    getVinculados(changePageVin, card);
  }, [changePageVin])

  /* Cards vinculados - final */


  const [searchPai, setSearchPai] = useState([]);

  return (
    <>


      {/*begin::Post*/}
      <div className="d-flex flex-column-fluid">
        {/*begin::Container*/}
        <div className="container">


          <div id="kt_app_toolbar" className="app-toolbar  pt-5">
            <div id="kt_app_toolbar_container" className="app-container d-flex flex-stack ">
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                  CARDS
                </h1>
                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                  <li className="breadcrumb-item text-muted">
                    <Link className="text-muted text-hover-primary" to="/academico/disciplinas">
                        Disciplina
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <span className="bullet bg-gray-400 w-5px h-2px" />
                  </li>
                  <li className="breadcrumb-item text-muted">
                    <Link className="text-muted text-hover-primary" to={"/academico/materias/"+sessionStorage.getItem('idMateria')}>
                      {sessionStorage.getItem('nomeDiscplina')}
                    </Link>                                
                  </li>
                  <li className="breadcrumb-item">
                    <span className="bullet bg-gray-400 w-5px h-2px" />
                  </li>
                  <li className="breadcrumb-item text-muted">
                    <Link className="text-muted text-hover-primary" to={"/academico/temas/"+sessionStorage.getItem('idTema')}>
                      {sessionStorage.getItem('nomeMateria')}
                    </Link>                                
                  </li>
                  <li className="breadcrumb-item">
                    <span className="bullet bg-gray-400 w-5px h-2px" />
                  </li>
                  <li className="breadcrumb-item text-muted">
                    {sessionStorage.getItem('nomeTema')}                                
                  </li>
                </ul>
              </div>
              <div className="d-flex align-items-center gap-2 gap-lg-3">
                <button className='btn btn-sm btn-primary' onClick={(e) => { newCard() }}><i className="fas fa-plus" />Adicionar</button>
              </div>
            </div>
          </div>

          <div className="card card-custom gutter-b example example-compact">
            <div className="mt-5">

              <LoadingOverlay
                active={loading}
                spinner
              >


                <table className="table table-striped" id="kt_table_users">

                  <thead className="bg-light-primary thead-inputs">
                    <Headerer
                      columns={[
                        { name: 'ID', id: 'id', searchable: true, sortable: true },
                        { name: 'Titulo', id: 'titulo', searchable: true, sortable: true },             
                        {
                          name: 'Tipo', id: 'tipo', searchable: false, sortable: true,
                          selects: [
                            { name: "Omissão", value: 'OMISSAO' }
                          ]
                        },
                        { name: 'Tags', id: 'tags', searchable: true, sortable: true },
                        { name: 'Resumo', id: 'action', searchable: false, sortable: false },
                        { name: 'Ação', id: 'action', searchable: false, sortable: false },
                      ]}
                      setSearchOrig={setSearchUsers}
                      searchOrig={searchUsers}
                    />


                  </thead>
                  <tbody className="text-gray-600 fw-bold">
                    {users.map((user) => {


                      return (
                        <>
                          <tr>
                            <td className='text-center max-width-id'>
                              {user.id}
                            </td>
                            <td className="">
                              {user.titulo}<br />
                              <small dangerouslySetInnerHTML={{ __html: user.card }} />
                            </td>
                            <td>
                              {user.tipo}
                            </td>
                            <td>
                              {user.tagsName.map(tag => {
                                return (<>
                                  <div class="badge badge-primary badge-tag mt-1 mb-1 fw-bolder cursor-pointer">{tag}</div>

                                </>)
                              })}

                            </td>
                            <td className='text-center'>
                                {user.texto_livre !== null || user.legislacao !== null || user.doutrina !== null || user.jurisprudencia !== null || user.sumulas !== null ?
                                    <span class="badge badge-success mr-2">Sim</span> 
                                :
                                    <span class="badge badge-secondary mr-2">Não</span>}
                            </td>
                            <td className="text-end">
                              <Dropdown>
                                <Dropdown.Toggle class="btn-drop btn btn-primary">
                                <i class="flaticon-more"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item>
                                            <a class="dropdown-item" onClick={e => { e.preventDefault(); editar(user) }} >Editar</a>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            <a class="dropdown-item" onClick={e => { e.preventDefault(); excluir(user) }} >Excluir</a>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                          </tr>

                        </>
                      )
                    })}



                  </tbody>
                </table>
              </LoadingOverlay>
              <Paginator maxPages={totPage} actPage={actPage} setNextPage={setChangePageReferreds} loading={loading} />

            </div>
          </div>

          {/*end::Post*/}
          <Modal
            enforceFocus={false}
            size={"lg"}
            show={modalIsOpen}
            onHide={() => handleFechaModal()}
          >
            <Modal.Header closeButton>
              <Modal.Title>Gerenciar Card</Modal.Title>
            </Modal.Header>
            <Modal.Body>


              <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 text-uppercase font-bold">
                <li class="nav-item">
                  <a
                    onClick={e => { e.preventDefault(); setTabSelected('cards'); }}
                    className={classNames('nav-link', { 'active': tabSelected === 'cards' })}
                  >
                    Cards
                  </a>
                </li>

                <li class="nav-item">
                  <a
                    onClick={e => { e.preventDefault(); setTabSelected('at'); }}
                    className={classNames('nav-link', { 'active': tabSelected === 'at' })}
                  >
                    Resumo
                  </a>
                </li>

                <li class="nav-item">
                  <a
                    onClick={e => { e.preventDefault(); setTabSelected('cards-vinculados'); }}
                    className={classNames('nav-link', { 'active': tabSelected === 'cards-vinculados' })}
                  >
                    Cards Vinculados
                  </a>
                </li>

                <li class="nav-item">
                  <a
                    onClick={e => { e.preventDefault(); setTabSelected('questoes-vinculadas'); }}
                    className={classNames('nav-link', { 'active': tabSelected === 'questoes-vinculadas' })}
                  >
                    Questões Vinculadas
                  </a>
                </li>

                {/* <li class="nav-item">
                      <a
                        onClick={e => { e.preventDefault(); setTabSelected('feedback'); }}
                        className={classNames('nav-link', { 'active': tabSelected === 'feedback' })}
                      >
                        Feedback
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        onClick={e => { e.preventDefault(); setTabSelected('logs'); }}
                        className={classNames('nav-link', { 'active': tabSelected === 'logs' })}
                      >
                        Logs
                      </a>
                    </li> */}
              </ul>


              {
                (() => {
                  switch (tabSelected) {
                    case 'cards':
                      return <div>


                        <h2 className="h4 fw-bold mb-5">Categorização:</h2>

                        <div class="mb-3">
                          <label for="" class="form-label">Disciplina</label>
                          <Select
                            value={disciplinas.find(
                              x => x.value == sessionStorage.getItem('atualDisciplina')
                            )}
                            options={disciplinas}
                            onChange={e => { setCard({ ...card, id_disciplina: e.value }); 
                                                             sessionStorage.setItem('atualDisciplina', e.value); 
                                                             getMaterias(e.value) }}
                            class="form-select"
                          />
                        </div>

                        <div class="mb-3">
                          <label for="" class="form-label">Matéria</label>
                          <LoadingOverlay
                            active={loadingMaterias}
                            spinner
                          >
                            <Select
                              value={materias.find(
                                x => x.value == sessionStorage.getItem('atualMateria')
                              )}
                              class="form-select" options={materias} 
                              onChange={e => { setCard({ ...card, id_materia: e.value });
                                              sessionStorage.setItem('atualMateria', e.value);  
                                              getTemas(e.value) }} />
                          </LoadingOverlay>
                        </div>

                        <div class="mb-3">
                          <label for="" class="form-label">Tema</label>
                          <LoadingOverlay
                            active={loadingTemas}
                            spinner
                          >
                            <Select
                              value={temas.find(
                                x => x.value == sessionStorage.getItem('atualTema')
                              )}
                              class="form-select" options={temas} 
                              onChange={e => { setCard({ ...card, id_tema: e.value });
                                              sessionStorage.setItem('atualMateria', e.value);
                                              getSubtemas(e.value) }} />
                          </LoadingOverlay>
                        </div>


                        <div class="mb-3">
                          <label for="" class="form-label">Subtema</label>
                          <LoadingOverlay
                            active={loadingSubtemas}
                            spinner
                          >
                            <Select
                              value={subtemas.find(
                                x => x.value === card?.id_subtema
                              )}
                              class="form-select" options={subtemas} onChange={e => { setCard({ ...card, id_subtema: e.value }) }} />
                          </LoadingOverlay>
                        </div>


                        <div class="mb-3">
                          <label for="" class="form-label">Tags</label>
                          <Select
                            isMulti
                            name="colors"
                            options={tags}
                            value={tags.filter(option => card.tags.includes(option.value))}
                            onChange={e => { handleTags(e) }}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                        </div>


                        <h2 className="h4 fw-bold mb-5">Card:</h2>

                        <div className="m-auto btn-tabs">
                          <a
                            onClick={e => { e.preventDefault(); setCard({ ...card, tipo: 'OMISSAO' }); }}
                            className={classNames('btn btn-sm btn-active-primary me-2 mb-2 px-10', { 'btn-primary': card.tipo === 'OMISSAO' })}
                          > Omissão de Palavra(s)
                          </a>

                          <a
                            onClick={e => { e.preventDefault(); setCard({ ...card, tipo: 'FREVER' }); }}
                            className={classNames('btn btn-sm btn-active-primary me-2 mb-2 px-10', { 'btn-primary': card.tipo === 'FREVER' })}
                          > Frente e Verso
                          </a>
                        </div>

                        {
                          (() => {
                            switch (card.tipo) {
                              case 'OMISSAO':
                                return <div>

                                  <div className="mb-3">
                                    <label for="" class="form-label">Título Card</label>
                                    <textarea value={card.titulo} onChange={e => { setCard({ ...card, titulo: e.target.value }) }} className="form-control" rows="2" />
                                  </div>

                                  <div className="">
                                    <label for="" class="form-label">Card</label>
                                    <ReactQuill value={card.card} onChange={e => { setCard({ ...card, card: e }) }} />
                                  </div>

                                  <div class="horizontal-scroll mt-2 mb-3">
                                    <a onClick={e => { e.preventDefault(); addTag('OMISSAO') }} className="btn btn-sm btn-bg-secondary btn-active-color-primary me-2">OMISSÃO [...]</a>
                                    <a onClick={e => { e.preventDefault(); addTag('ESCOLHA', ["É", "NÃO É"]) }} className="btn btn-sm btn-bg-secondary btn-active-color-primary me-2">SE / NÃO SE</a>
                                    <a onClick={e => { e.preventDefault(); addTag('ESCOLHA', ["DEVE", "NÃO DEVE"]) }} className="btn btn-sm btn-bg-secondary btn-active-color-primary me-2">DEVE / NÃO DEVE</a>
                                  </div>

                                  <div className="mt-3">
                                    <label for="" class="form-label">Conteúdo Extra</label>
                                    <textarea value={card.extra} onChange={e => { setCard({ ...card, extra: e.target.value }) }} className="form-control" rows="2" />
                                  </div>

                                  <hr></hr>

                              







                                </div>;

                              case 'FREVER':
                                return <div>

                                  frente

                                </div>;
                              default:
                                return null;
                            }
                          })()
                        }






                      </div>;

                    case 'at':
                      return <div>

                        <div className="card-body">
                          <AprofundamentoTematico set={setCard} get={card} />
                        </div>

                      </div>;

                    case 'cards-vinculados':
                      return <div>

                        <div className="card-body">
                          <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer"><div className="table-responsive"><table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_table_users">
                            <thead>
                              <Headerer
                                columns={[
                                  { name: 'ID', id: 'id', searchable: true, sortable: true },
                                  { name: 'Titulo', id: 'titulo', searchable: true, sortable: true },
                                  {
                                    name: 'Tipo', id: 'type', searchable: false, sortable: true,
                                    selects: [
                                      { name: "Omissão", value: 'OMISSAO' }
                                    ]
                                  },
                                  { name: 'Ação', id: 'action', searchable: false, sortable: false },
                                ]}
                                setSearchOrig={setSearchVin}
                                searchOrig={searchVin}
                              />


                            </thead>
                            <tbody className="text-gray-600 fw-bold">
                              {vinculados.map((user) => {
                                return (
                                  <>
                                    <tr className="odd">
                                      <td>
                                        {user.id}
                                      </td>
                                      <td className="d-flex align-items-center coluna-conteudo-card">
                                        {user.titulo}
                                      </td>
                                      <td>
                                        {user.tipo}
                                      </td>
                                      <td className="text-end">
                                        <a onClick={e => { e.preventDefault(); editar(user) }} className="btn btn-light btn-active-light-primary btn-sm">Gerenciar</a>
                                      </td>
                                    </tr>

                                  </>
                                )
                              })}



                            </tbody>
                          </table>
                          </div>
                            <Paginator maxPages={totVinPage} actPage={actVinPage} setNextPage={setChangePageVin} loading={loadingVin} />

                          </div>





                        </div>

                      </div>;


                    case 'questoes-vinculadas':
                      return <div>

                        <div className="card-body">




                        </div>

                      </div>;


                    case 'feedback':
                      return <div>

                        <div className="card-body">
                          Feedback
                        </div>

                      </div>;


                    case 'logs':
                      return <div>

                        <div className="card-body">
                          Logs
                        </div>

                      </div>;
                    default:
                      return null;
                  }
                })()
              }






            </Modal.Body>

            <Modal.Footer>
              <button onClick={() => handleFechaModal()} type="button" class="btn btn-light" data-bs-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
              <ButtonLoading isLoading={loadingSaveCard} onClick={e => { e.preventDefault(); salvarCard() }} type="button" className="btn btn-success"><i className="fas fa-save" />Salvar</ButtonLoading>
            </Modal.Footer>
          </Modal>



        </div>
      </div>
    </>
  );

}



export default withRouter(AcademicoCards);                  