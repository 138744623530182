import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';

import Api from '../../services/api';
import LayoutInst from '../../layout/index_inst.jsx';


const Error404 = () => {

    useEffect(() => {
        document.title = "404 | 2Close.com"
    }, []);



    return (
        <>

            <LayoutInst>
                {/* Content*/}
                <div>
                    404
                </div>
            </LayoutInst>
        </>
    );

}



export default withRouter(Error404);                  